import Prismjs from 'prismjs'
import {Link} from "react-router-dom";
let code  =
    `var axios = require('axios');
var data = {
   "first_name": "Killi",
   "last_name": "Vawulence",
   "phone": "09067852100",
   "company": "LSc1cvaVzDHADtvHwUlQ",
   "preferred_bank": "194",
   "email": "killi@gmail.com",
   "dob": "1997-07-25",
   "bvn": ""
   };

var config = {
  method: 'post',
maxBodyLength: Infinity,
  url: '{{localhost}}/api/v1/users/api-register',
  headers: { },
  data : data
};

axios(config)
.then(function (response) {
  console.log(JSON.stringify(response.data));
})
.catch(function (error) {
  console.log(error);
});`
const html = Prismjs.highlight(code, Prismjs.languages.javascript, 'javascript');
// console.log(html)
const ApiSection = () => {

    return (
        <div className={' p-8 rounded-3xl md:my-24 my-8  relative isolate lg:px-8'}>
            <div className="grid m-auto grid-cols-1  gap-10 max-w-6xl md:grid-cols-2">
                <div className="">
                    <h4 className="text-3xl font-bold leading-8">Our Top Class API Infrastructure</h4>
                    <p className="mt-4 text-md text-gray-600 leading-7">{"Wired Banking's management system provides user-friendly APIs for quick and easy integration with partners using advanced technology. The APIs available include Nibss integration, Virtual Payment Collection, Nuban Account Creation, Webhooks notifications, Cgate USSD, Payattitude, Cashless, and Bank transfer. These APIs can be accessed through different transactional channels such as ATM Cards, POS WhatsApp Banking, and Shared or Dedicated USSD Code Integration."}</p>
                    <Link to={'https://safe.wbalite.com/register'} target={"_blank"} className="text-sm font-semibold  inline-block mt-8 bg-amber-400  rounded-full px-6 py-4 hover:bg-amber-500 hover:text-amber-50 text-amber-950">Get Started</Link>
                </div>
                <div className=" rounded-tr-3xl rounded-tl-3xl  blue bg-gradient-to-r from-amber-500 to-amber-700 pl-12 pt-12">

                    <div className="border-b border shadow rounded-tl-2xl border-slate-800 bg-slate-950">
                        <div className="rounded-tl-2xl bg-slate-800 flex rounded-tr-2xl  ">
                            <div className="  bg-slate-800 p-4 rounded-tl-2xl border-b border-r border-slate-500 ">
                                <p className="text-sm text-left text-slate-300 flex align-center"><span>Api.js</span> <span className={'ml-2'}>&#10006;</span></p>
                            </div>
                            <div className="bg-slate-950 p-4 w-full rounded-tr-2xl  ">
                                <p className="text-sm text-left text-slate-300">Notifications</p>
                            </div>
                        </div>
                        <div className={'w-full overflow-scroll   language-javascript bg-slate-950 scrollbar-hide text-gray-400 font-mono p-8  whitespace-pre-wrap'}>
                            <pre className={'h-40 md:h-60'} dangerouslySetInnerHTML={{__html:html}}/>
                            {/*{html}*/}
                        </div>

                    </div>
                </div>
            </div>
            {/*<div className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-2xl sm:-top-80"*/}
            {/*     aria-hidden="true">*/}
            {/*    <div*/}
            {/*        className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#E5A924] to-[#ffffff] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"*/}
            {/*        style={{clipPath: 'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)'}}></div>*/}
            {/*</div>*/}
        </div>
    );
};

export default ApiSection;