import PriceCard from "../../components/pricing/priceCard";
import planData from "../../components/pricing/planData";
const Plans = ({type,currency,value}) => {

    return (
        <div className={'grid grid-cols-1 gap-5 max-w-6xl m-auto py-8 md:grid-cols-2 lg:grid-cols-3'}>
            {
                planData.map((e,i)=> <PriceCard currency={currency} value={value} data={e} key={e.title} type={type}/>)
            }
        </div>
    );
};


export default Plans;