import React from 'react';
import {CheckIcon} from "@heroicons/react/20/solid";
import {Link} from "react-router-dom";

const OnboardRequirements = () => {
    return (
        <div className={' p-8 rounded-3xl relative isolate lg:px-8'}>

            <h4 className="text-3xl my-24 font-bold leading-8 text-center">WBA Onboarding Requirements
            </h4>
            <div className="grid m-auto grid-cols-1  gap-10  max-w-6xl md:grid-cols-2 sm:flex-col-reverse">
                <div className="">
                    <p className="mt-4 text-md text-gray-600 leading-7">Declaimer: We do not give loans. We only offer our platform as a service and work with financial institutions with operating licenses only.
                    </p>
                    <p className=" my-4 rounded-md text-amber-700 ">  Note: If you are not a financial institution like MFB, MFI, Fintech, cooperative society, thrift collector, Sacco Fintech, or lender, do not register because your account will not be approved.

                    </p>
                    <p className="text-md text-gray-600 leading-7">  To get started with us, kindly register a new account, ensure to use a valid email and phone number as OTP will be sent.
                        Please note: Every country can use Wired banking lite (SAAS), US, UK,  NIGERIA, GHANA, ZAMBIA, UGANDA, TANZANIA, SOUTH AFRICA, ----etc
                    </p>

                    <h6 className="text-lg text-gray-800 font-bold my-4">REQUIRED STEPS TO GO LIVE.</h6>

                    <ul className={'list-none'}>
                        <li className={'flex align-middle mt-6 items-center'}>
                            <span className={' rounded-full w-6 h-6 flex justify-center align-middle'}><CheckIcon className={'w-6 text-green-600'}/></span>
                            <span className={'text-base leading-7 text-gray-600 ml-2'}>
                        Signup (complete email and phone verification)
                       </span>
                        </li>
                        <li className={'flex align-middle mt-6 items-center'}>
                            <span className={' rounded-full w-6 h-6 flex justify-center align-middle'}><CheckIcon className={'w-6 text-green-600'}/></span>
                            <span className={'text-base leading-7 text-gray-600 ml-2'}>  Login and pay your setup fee $15 (payable in your country's currency or USD)

                                  </span>
                        </li>
                        <li className={'flex align-middle mt-6 items-center'}>
                            <span className={' rounded-full w-6 h-6 flex justify-center align-middle'}><CheckIcon className={'w-6 text-green-600'}/></span>
                            <span className={'text-base leading-7 text-gray-600 ml-2'}>Complete your KYC
                        Setup and go live.
                                  </span>
                        </li>
                    </ul>
                    <a target={"_blank"}  rel="noreferrer"  href={'https://forms.gle/Gxq8E3VsguPbHMpG7'} className="text-sm inline-block font-semibold mt-8 bg-amber-400 rounded-full px-6 py-4 hover:bg-amber-500  text-gray-900">Submit KYC </a>
                </div>
                <div className=" rounded-3xl  justify-center" >

                    <h6 className="text-lg text-gray-800 font-bold my-4">KYC DETAILS INCLUDE.</h6>

                    <ul className={'list-none'}>
                        <li className={'flex align-middle mt-6 items-center'}>
                            <span className={' rounded-full w-6 h-6 flex justify-center align-middle'}><CheckIcon className={'w-6 text-green-600'}/></span>
                            <span className={'text-base leading-7 textgray-600 ml-2'}>
                        Valid NIN (National Identity Number).
                       </span>
                        </li>
                        <li className={'flex align-middle mt-6 items-center'}>
                            <span className={' rounded-full w-6 h-6 flex justify-center align-middle'}><CheckIcon className={'w-6 text-green-600'}/></span>
                            <span className={'text-base leading-7 textgray-600 ml-2'}>
                        Business Registration Documents

                                  </span>
                        </li>
                        <li className={'flex align-middle mt-6 items-center'}>
                            <span className={' rounded-full w-6 h-6 flex justify-center align-middle'}><CheckIcon className={'w-6 text-green-600'}/></span>
                            <span className={'text-base leading-7 textgray-600 ml-2'}>
                        Other Company Document
                        Certificate of operation/license – (Sacco, Fintech, Cooperative society, MFI, MFB, NGO, Thrift collector. Lending certificate)
                        Valid government-issued ID (International Passport, Driver's license, Voter's card, National I.D card)
                        Recent passport
                        Proof of address (Utility Bill: Nepa Bill, Water Bill, Waste Bill)
                                  </span>
                        </li>
                        <li className={'flex align-middle mt-6 items-center'}>
                            <span className={' rounded-full w-6 h-6 flex justify-center align-middle'}><CheckIcon className={'w-6 text-green-600'}/></span>
                            <span className={'text-base leading-7 textgray-600 ml-2'}>
                        Other Company Document
                                  </span>
                        </li>
                        <li className={'flex align-middle mt-6 items-center'}>
                            <span className={' rounded-full w-6 h-6 flex justify-center align-middle'}><CheckIcon className={'w-6 text-green-600'}/></span>
                            <span className={'text-base leading-7 textgray-600 ml-2'}>

                        Certificate of operation/license – (Sacco, Fintech, Cooperative society, MFI, MFB, NGO, Thrift collector. Lending certificate)
                                  </span>
                        </li>
                        <li className={'flex align-middle mt-6 items-center'}>
                            <span className={' rounded-full w-6 h-6 flex justify-center align-middle'}><CheckIcon className={'w-6 text-green-600'}/></span>
                            <span className={'text-base leading-7 textgray-600 ml-2'}>

                        Valid government-issued ID (International Passport, Driver's license, Voter's card, National I.D card)
                        Recent passport
                                  </span>
                        </li>
                        <li className={'flex align-middle mt-6 items-center'}>
                            <span className={' rounded-full w-6 h-6 flex justify-center align-middle'}><CheckIcon className={'w-6 text-green-600'}/></span>
                            <span className={'text-base leading-7 textgray-600 ml-2'}>

                        Proof of address (Utility Bill: Nepa Bill, Water Bill, Waste Bill)
                                  </span>
                        </li>
                    </ul>


                </div>

            </div>
            <div className="mx-auto my-8 max-w-2xl text-center">

                <h6 className="text-lg text-gray-800 font-bold my-4">SUBSCRIPTION</h6>
                <p className='text-md text-gray-600 leading-7'> After 30 days of going live, you will be required to subscribe to a plan, which you can maintain monthly to keep using the wired banking management system (SAAS) & (BAAS) as the first month is totally free. </p>
                <Link to={'/pricing'} className="text-sm inline-block font-semibold mt-8 bg-amber-400 rounded-full px-6 py-4 hover:bg-amber-500  text-gray-900"> Pricing </Link>
            </div>
        </div>
    );
};

export default OnboardRequirements;