import React from "react";
import interswitch from '../../assets/Interswitch_logo.svg.png';
import verve from '../../assets/verve.svg';
import providus from '../../assets/providus.svg';
import vfd from '../../assets/VFD-MFB-Full-Logo-2048x746.png';
import nibss from '../../assets/nibss.png';
import coral from '../../assets/coral.svg';
const Partners = () => {

    return (
        <div className={'-mt-10 md:-mt-28'}>
            <div className=" px-12 md:px-0 mx-auto max-w-6xl">
                <h6 className="font-bold text-xl text-center text-gray-700">Trusted By</h6>
            </div>
                <div  className={'grid grid-cols-2 gap-4 m-auto max-w-6xl md:grid-cols-3 py-12 px-4 text-center'}>
                  <div className=" flex justify-center align-center">
                      <img src={interswitch} width={"70"} height={70} className={'md:w-28  w-20'} alt={'Interwitch'}/>
                  </div>
                    <div className=" flex justify-center align-center">

                        <img src={verve} width={"70"} height={70} className={'md:w-28  w-20'} alt={'Verve'}/>
                    </div>
                    <div className=" flex justify-center align-center">

                        <img src={providus} width={"70"} height={70} className={'md:w-28  w-20'} alt={'Providus'}/>
                    </div>
                    <div className=" flex justify-center align-center">
                        <img src={vfd} width={"70"} height={70} className={'md:w-28 h-12 w-20'} alt={'vfd'}/>
                    </div>
                    <div className=" flex justify-center align-center">
                        <img src={nibss} width={"70"} height={70} className={'md:w-28 h-12 w-20'} alt={'nibss'}/>
                    </div>
                    <div className=" flex justify-center align-center">
                        <img src={coral} width={"70"} height={70} className={'md:w-28 h-12 w-20'} alt={'Coral Pay'}/>
                    </div>
                </div>
        </div>
    );
};

export default Partners;