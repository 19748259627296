import priceListData,{aptData} from "../../components/pricing/priceListData";
import {CheckIcon} from "@heroicons/react/20/solid";
const PricingTables = () => {
    return (
        <div className={'my-12 max-w-6xl m-auto px-6 md:px-0'}>
                <h3 className="text-3xl font-bold text-center">Transaction Charges breakdown</h3>
                 <p className="text-md  text-gray-600 mt-2 text-center">Note: This is subject to review from time to time and transaction charges apply only if addons features are used. <span className={''}>you only pay for what you use</span></p>

            <div className="my-4 rounded-md text-amber-700 text-center">
                Kindly Note Wired Banking does not charge its users any hidden fee
            </div>
            <h4 className="text-xl font-bold mt-8">Inward /Credit/Deposit VIRTUAL ACCOUNT COLLECTION FEE</h4>
            <div className="bg-gray-100 p-4 mt-4 rounded-2xl text-amber-700">
                <ul className={'list-none'}>
                    <li className={'flex align-middle mt-1 items-center'}>
                        <span className={' rounded-full w-10 h-10 flex justify-center align-middle'}><CheckIcon className={'w-6 text-green-600'}/></span>
                        <span className={'text-sm text-gray-700 ml-2'}>Providus Collection 0.75% capped N150</span>
                    </li>
                    <li className={'flex align-middle mt-1 items-center'}>
                        <span className={' rounded-full w-10 h-10 flex justify-center align-middle'}><CheckIcon className={'w-6 text-green-600'}/></span>
                        <span className={'text-sm text-gray-700 ml-2'}>GTB vi Collection 0.75% capped N700</span>
                    </li>
                    <li className={'flex align-middle mt-1 items-center'}>
                        <span className={' rounded-full w-10 h-10 flex justify-center align-middle'}><CheckIcon className={'w-6 text-green-600'}/></span>
                        <span className={'text-sm text-gray-700 ml-2'}>Coronation Collection 0.75% +30 capped N150</span>
                    </li>
                    <li className={'flex align-middle mt-1 items-center'}>
                        <span className={' rounded-full w-10 h-10 flex justify-center align-middle'}><CheckIcon className={'w-6 text-green-600'}/></span>
                        <span className={'text-sm text-gray-700 ml-2'}>Wema Collection 0.75% +30 capped N150</span>
                    </li>
                    <li className={'flex align-middle mt-1 items-center'}>
                        <span className={' rounded-full w-10 h-10 flex justify-center align-middle'}><CheckIcon className={'w-6 text-green-600'}/></span>
                        <span className={'text-sm text-gray-700 ml-2'}>Sparkle Collection 0.75% +30 capped N150</span>
                    </li>
                    <li className={'flex align-middle mt-1 items-center'}>
                        <span className={' rounded-full w-10 h-10 flex justify-center align-middle'}><CheckIcon className={'w-6 text-green-600'}/></span>
                        <span className={'text-sm text-gray-700 ml-2'}>Asset Matrix Collection 0.75% capped N100</span>
                    </li>
                    <li className={'flex align-middle mt-1 items-center'}>
                        <span className={' rounded-full w-10 h-10 flex justify-center align-middle'}><CheckIcon className={'w-6 text-green-600'}/></span>
                        <span className={'text-sm text-gray-700 ml-2'}>VFD Collection 1% +10 capped N150</span>
                    </li>
                    <li className={'flex align-middle mt-1 items-center'}>
                        <span className={' rounded-full w-10 h-10 flex justify-center align-middle'}><CheckIcon className={'w-6 text-green-600'}/></span>
                        <span className={'text-sm text-gray-700 ml-2'}>Kuda Collection 0.75% capped N150</span>
                    </li>
                    <li className={'flex align-middle mt-1 items-center'}>
                        <span className={' rounded-full w-10 h-10 flex justify-center align-middle'}><CheckIcon className={'w-6 text-green-600'}/></span>
                        <span className={'text-sm text-gray-700 ml-2'}>WBA Collection 0.5% capped N50</span>
                    </li>
                </ul>
            </div>
            <div className=" my-4 rounded-md text-amber-700 text-center">
                Please Note: All Wired Banking Internal Account transactions are totally free: such as customer registration, account opening, Cash deposits, Cash Withdrawals, Cash and wallet Loan repayment, Investments etc.
            </div>
            <p className="text-md  font-bold mt-8">You only get to pay if you are using third-party service as listed below. Item 2- 15 are totally optional</p>
            <div className="bg-gray-100 p-4 mt-4  rounded-2xl text-amber-700 grid grid-cols-1 md:grid-cols-2">
                <ul className={'list-none'}>
                    <li className={'flex align-middle mt-1 items-center'}>
                        <span className={' rounded-full w-10 h-10 flex justify-center align-middle'}><CheckIcon className={'w-6 text-green-600'}/></span>
                        <span className={'text-sm text-gray-700 ml-2'}>Prefund Card: N0.</span>
                    </li>
                    <li className={'flex align-middle mt-1 items-center'}>
                        <span className={' rounded-full w-10 h-10 flex justify-center align-middle'}><CheckIcon className={'w-6 text-green-600'}/></span>
                        <span className={'text-sm text-gray-700 ml-2'}>Outward/ Payout- Bank transfer = N25 flat any amount</span>
                    </li>
                    <li className={'flex align-middle mt-1 items-center'}>
                        <span className={' rounded-full w-10 h-10 flex justify-center align-middle'}><CheckIcon className={'w-6 text-green-600'}/></span>
                        <span className={'text-sm text-gray-700 ml-2'}>Loan decision lookup N30 and above</span>
                    </li>
                    <li className={'flex align-middle mt-1 items-center'}>
                        <span className={' rounded-full w-10 h-10 flex justify-center align-middle'}><CheckIcon className={'w-6 text-green-600'}/></span>
                        <span className={'text-sm text-gray-700 ml-2'}>Loan Credit Check – N30</span>
                    </li>
                    <li className={'flex align-middle mt-1 items-center'}>
                        <span className={' rounded-full w-10 h-10 flex justify-center align-middle'}><CheckIcon className={'w-6 text-green-600'}/></span>
                        <span className={'text-sm text-gray-700 ml-2'}>CRC loan Lookup N100</span>
                    </li>
                    <li className={'flex align-middle mt-1 items-center'}>
                        <span className={' rounded-full w-10 h-10 flex justify-center align-middle'}><CheckIcon className={'w-6 text-green-600'}/></span>
                        <span className={'text-sm text-gray-700 ml-2'}>Third-party SMS Whitelisting to bypass DND= N5k -optional ( Takes 7 – 14 days)</span>
                    </li>
                    <li className={'flex align-middle mt-1 items-center'}>
                        <span className={' rounded-full w-10 h-10 flex justify-center align-middle'}><CheckIcon className={'w-6 text-green-600'}/></span>
                        <span className={'text-sm text-gray-700 ml-2'}>Self SMS API integration (post/get)- N0</span>
                    </li>
                    <li className={'flex align-middle mt-1 items-center'}>
                        <span className={' rounded-full w-10 h-10 flex justify-center align-middle'}><CheckIcon className={'w-6 text-green-600'}/></span>
                        <span className={'text-sm text-gray-700 ml-2'}>Transaction SMS Alert = N3</span>
                    </li>
                    <li className={'flex align-middle mt-1 items-center'}>
                        <span className={' rounded-full w-10 h-10 flex justify-center align-middle'}><CheckIcon className={'w-6 text-green-600'}/></span>
                        <span className={'text-sm text-gray-700 ml-2'}>Automated payroll N20</span>
                    </li>
                    <li className={'flex align-middle mt-1 items-center'}>
                        <span className={' rounded-full w-10 h-10 flex justify-center align-middle'}><CheckIcon className={'w-6 text-green-600'}/></span>
                        <span className={'text-sm text-gray-700 ml-2'}>BVN verification = N70</span>
                    </li>
                </ul>
                <ul className={'list-none'}>


                    <li className={'flex align-middle mt-1 items-center'}>
                        <span className={' rounded-full w-10 h-10 flex justify-center align-middle'}><CheckIcon className={'w-6 text-green-600'}/></span>
                        <span className={'text-sm text-gray-700 ml-2'}>NIN Verification = N100</span>
                    </li>
                    <li className={'flex align-middle mt-1 items-center'}>
                        <span className={' rounded-full w-10 h-10 flex justify-center align-middle'}><CheckIcon className={'w-6 text-green-600'}/></span>
                        <span className={'text-sm text-gray-700 ml-2'}>Bills Payment – N20</span>
                    </li>
                    <li className={'flex align-middle mt-1 items-center'}>
                        <span className={' rounded-full w-10 h-10 flex justify-center align-middle'}><CheckIcon className={'w-6 text-green-600'}/></span>
                        <span className={'text-sm text-gray-700 ml-2'}>Prepaid Physical card ( Verve) is now capped at N1050</span>
                    </li>
                    <li className={'flex align-middle mt-1 items-center'}>
                        <span className={' rounded-full w-10 h-10 flex justify-center align-middle'}><CheckIcon className={'w-6 text-green-600'}/></span>
                        <span className={'text-sm text-gray-700 ml-2'}>Direct Switch Physical card ( Verve) is now capped at N150</span>
                    </li>
                    <li className={'flex align-middle mt-1 items-center'}>
                        <span className={' rounded-full w-10 h-10 flex justify-center align-middle'}><CheckIcon className={'w-6 text-green-600'}/></span>
                        <span className={'text-sm text-gray-700 ml-2'}>NGN Virtual card ( Visa) N500 flat. & Funding N1.5$</span>
                    </li>
                    <li className={'flex align-middle mt-1 items-center'}>
                        <span className={' rounded-full w-10 h-10 flex justify-center align-middle'}><CheckIcon className={'w-6 text-green-600'}/></span>
                        <span className={'text-sm text-gray-700 ml-2'}>USD Virtual card (Master) $5 flat. & Funding N1.5$</span>
                    </li>
                    <li className={'flex align-middle mt-1 items-center'}>
                        <span className={' rounded-full w-10 h-10 flex justify-center align-middle'}><CheckIcon className={'w-6 text-green-600'}/></span>
                        <span className={'text-sm text-gray-700 ml-2'}>Nibss Direct Debit Mandate Minimum N100 and capped N1,500</span>
                    </li>
                    <li className={'flex align-middle mt-1 items-center'}>
                        <span className={' rounded-full w-10 h-10 flex justify-center align-middle'}><CheckIcon className={'w-6 text-green-600'}/></span>
                        <span className={'text-sm text-gray-700 ml-2'}>Remita Direct Debit mandate = 1% + N20 capped N1,000</span>
                    </li>
                    <li className={'flex align-middle mt-1 items-center'}>
                        <span className={' rounded-full w-10 h-10 flex justify-center align-middle'}><CheckIcon className={'w-6 text-green-600'}/></span>
                        <span className={'text-sm text-gray-700 ml-2'}>Cgate USSD collection fee 1% + N20 capped N1,500</span>
                    </li>
                    <li className={'flex align-middle mt-1 items-center'}>
                        <span className={' rounded-full w-10 h-10 flex justify-center align-middle'}><CheckIcon className={'w-6 text-green-600'}/></span>
                        <span className={'text-sm text-gray-700 ml-2'}>Physical Card Maintenance N100 quarterly ( Every 3 months N100 flat.).</span>
                    </li>
                </ul>
            </div>
           {
                    priceListData.map((e,i)=>(

                    <div key={e.type + i}>
                        <h5 className="text-xl font-bold mt-12">{e.type}</h5>
                        <p className="text-md mt-2  text-gray-600">{e.description}</p>
                        <div className="border-slate-300 overflow-x-auto  border rounded-xl mt-8">
                        <table  className="table-auto    min-w-full  divide-gray-200">
                            <thead className={'bg-gray-100  '}>
                            <tr className={'py-4 '}>
                                <th className={' py-5 px-6 text-left text-sm rounded-tl-xl font-bold text-gray-700 tracking-wider'}>Services</th>
                                <th className={' py-5 px-6 text-left text-sm font-bold text-gray-700 tracking-wider'}>Narration</th>
                                <th className={' py-5 px-6 text-left text-sm  rounded-tr-xl font-bold text-gray-700 tracking-wider '}>Amount</th>
                            </tr>
                            </thead>
                            <tbody className={'divide-y divide-gray-200 tab'}>
                            {
                                e.data.map((ev,j)=>(

                                    <tr key={ev.service + j}>
                                        <td className={' py-5 px-6 text-left text-sm  text-gray-700 tracking-wider'}>{ev.service}</td>
                                        <td className={' py-5 px-6 text-left text-sm  text-gray-700 tracking-wider'}>{ev.narration}</td>
                                        <td className={' py-5 px-6 text-left text-sm  text-gray-700 tracking-wider'}>{ev.cost}</td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>

                        </div>

                        {
                            e.meta?e.meta():null
                        }
                    </div>))
                }

                    <div>

                        <h4 className="text-2xl mt-8 font-bold">APPLICATION PRICING</h4>
                        <div className="border-slate-300 overflow-x-auto  border rounded-xl mt-8">
                        <table  className="table-auto    min-w-full  divide-gray-200">
                            <thead className={'bg-gray-100  '}>
                            <tr className={'py-4 '}>
                                <th className={' py-5 px-6 text-left text-sm rounded-tl-xl font-bold text-gray-700 tracking-wider'}>APP TYPE</th>
                                <th className={' py-5 px-6 text-left text-sm font-bold text-gray-700 tracking-wider'}>Details</th>
                                <th className={' py-5 px-6 text-left text-sm  rounded-tr-xl font-bold text-gray-700 tracking-wider '}>Cost</th>
                            </tr>
                            </thead>
                            <tbody className={'divide-y divide-gray-200 tab'}>
                            {
                                aptData.map((ev,j)=>(

                                    <tr key={ev.type + j} className={'hover:bg-gray-100'}>
                                        <td className={' py-5 px-6 text-left text-sm  text-gray-700 tracking-wider'}>{ev.type}</td>
                                        <td className={' py-5 px-6 text-left text-sm  text-gray-700 tracking-wider'}>{ev.details}</td>
                                        <td className={' py-5 px-6 text-left text-sm  text-gray-700 tracking-wider'}>{ev.cost}</td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>


                        </div>
                    </div>

            <h4 className="text-2xl mt-8 font-bold">ATM CARD PRICING</h4>
            <h2 className="text-lg font-bold mb-2 mt-4">PHYSICAL ATM CARD PRICING</h2>

            <h3 className="text-lg font-bold mb-2">1. PREFUND CARD* (prepaid)</h3>
            <p className="text-gray-600 mb-2">This card is not linked to a wallet and requires manual funding before
                usage. You can withdraw funds at ATM galleries, use it at POS terminals, and pay for services online
                like a regular ATM card.</p>
            <p className="text-gray-600 mb-4">Card funding fee is zero.</p>
            <p className="text-gray-600 mb-4"><span className={'text-amber-500 text-bold'}>WBA PREFUND CARD: </span>You can request the card via your dashboard, and we will
                print it out and ship it to your location. This option costs N1,050 per card (delivery fee exclusive).
                The minimum request before printing is 20 cards.</p>

            <h3 className="text-lg font-bold mb-2">2. DIRECT CARD (SWITCHER)</h3>
            <p className="text-gray-600 mb-2">You are required to pay a one-time connection fee of N20,000 to <span className={'text-amber-500 text-bold'}>WIRED
                BANKING CARD BIM</span>. The fee is paid directly to WBA after an invoice is raised.</p>
            <p className="text-gray-600 mb-4">You do not need to write or submit a request like the prepaid/Prefund
                card. Our Direct Card switch has a plain design. You simply need to get an ID card printer from any
                vendor (e.g., Jumia), write your customer's name and logo on the card, and link it to the customer's
                profile.</p>
            <p className="text-gray-600 mb-4">Customers do not need to prefund the card before they can use it. The
                amount in their savings wallet can be accessed directly from the card. However, they are limited to
                N50,000 per day and can request a review or upgrade to a higher tier.</p>
            <p className="text-gray-600"><span className={'text-amber-500 text-bold'}>The price per card is N1,700, and the current minimum purchase is 100 cards.</span>
                You can now issue this card instantly as you open an account for your customer.</p>



            <div className="border-slate-300 overflow-x-auto  border rounded-xl mt-8">
                <table  className="table-auto    min-w-full  divide-gray-200">
                    <thead className={'bg-gray-100  '}>
                    <tr className={'py-4 '}>
                        <th className={' py-5 px-6 text-left text-sm rounded-tl-xl font-bold text-gray-700 tracking-wider'}>FEES</th>
                        <th className={' py-5 px-6 text-left text-sm font-bold text-gray-700 tracking-wider'}>MASTERCARD</th>
                        <th className={' py-5 px-6 text-left text-sm  rounded-tr-xl font-bold text-gray-700 tracking-wider '}>VERVE</th>
                    </tr>
                    </thead>
                    <tbody className={'divide-y divide-gray-200 tab'}>
                            <tr  className={'hover:bg-gray-100'}>
                                <td className={' py-5 px-6 text-left text-sm  text-gray-700 tracking-wider'}>Card		</td>
                                <td className={' py-5 px-6 text-left text-sm  text-gray-700 tracking-wider'}>N2,500</td>
                                <td className={' py-5 px-6 text-left text-sm  text-gray-700 tracking-wider'}>N1,700</td>
                            </tr>
                            <tr  className={'hover:bg-gray-100'}>
                                <td className={' py-5 px-6 text-left text-sm  text-gray-700 tracking-wider'}>Monthly Card maintenance </td>
                                <td className={' py-5 px-6 text-left text-sm  text-gray-700 tracking-wider'}>N100 (per month)</td>
                                <td className={' py-5 px-6 text-left text-sm  text-gray-700 tracking-wider'}>N100 (per month)</td>
                            </tr>
                            <tr  className={'hover:bg-gray-100'}>
                                <td className={' py-5 px-6 text-left text-sm  text-gray-700 tracking-wider'}>ATM Withdrawal fee	</td>
                                <td className={' py-5 px-6 text-left text-sm  text-gray-700 tracking-wider'}>N50</td>
                                <td className={' py-5 px-6 text-left text-sm  text-gray-700 tracking-wider'}>N50</td>
                            </tr>
                            <tr  className={'hover:bg-gray-100'}>
                                <td className={' py-5 px-6 text-left text-sm  text-gray-700 tracking-wider'}>Quarterly Scheme Maintenance fee		</td>
                                <td className={' py-5 px-6 text-left text-sm  text-gray-700 tracking-wider'}>N50	</td>
                                <td className={' py-5 px-6 text-left text-sm  text-gray-700 tracking-wider'}>N50</td>
                            </tr>
                            <tr  className={'hover:bg-gray-100'}>
                                <td className={' py-5 px-6 text-left text-sm  text-gray-700 tracking-wider'}>OTP Delivery fees(Safetoken & 3DS)		</td>
                                <td className={' py-5 px-6 text-left text-sm  text-gray-700 tracking-wider'}>N15	</td>
                                <td className={' py-5 px-6 text-left text-sm  text-gray-700 tracking-wider'}>N4</td>
                            </tr>
                    </tbody>
                </table>


            </div>

            <h3 className="text-lg font-bold my-2">VIRTUAL ATM CARD PRICING </h3>
            <p className="text-gray-600 mb-2">Card can be use globally for online payment.</p>
            <div className="border-slate-300 overflow-x-auto  border rounded-xl mt-8">
                <table  className="table-auto    min-w-full  divide-gray-200">
                    <thead className={'bg-gray-100  '}>
                    <tr className={'py-4 '}>
                        <th className={' py-5 px-6 text-left text-sm rounded-tl-xl font-bold text-gray-700 tracking-wider'}>FEES</th>
                        <th className={' py-5 px-6 text-left text-sm font-bold text-gray-700 tracking-wider'}>USD MASTERCARD</th>
                        <th className={' py-5 px-6 text-left text-sm  rounded-tr-xl font-bold text-gray-700 tracking-wider '}>NGN VERVE</th>
                    </tr>
                    </thead>
                    <tbody className={'divide-y divide-gray-200 tab'}>
                            <tr  className={'hover:bg-gray-100'}>
                                <td className={' py-5 px-6 text-left text-sm  text-gray-700 tracking-wider'}>Card		</td>
                                <td className={' py-5 px-6 text-left text-sm  text-gray-700 tracking-wider'}>$3</td>
                                <td className={' py-5 px-6 text-left text-sm  text-gray-700 tracking-wider'}>N500</td>
                            </tr>
                            <tr  className={'hover:bg-gray-100'}>
                                <td className={' py-5 px-6 text-left text-sm  text-gray-700 tracking-wider'}>Monthly Card maintenance 		</td>
                                <td className={' py-5 px-6 text-left text-sm  text-gray-700 tracking-wider'}>$1  (per month)</td>
                                <td className={' py-5 px-6 text-left text-sm  text-gray-700 tracking-wider'}>N50 (per month)</td>
                            </tr>
                            <tr  className={'hover:bg-gray-100'}>
                                <td className={' py-5 px-6 text-left text-sm  text-gray-700 tracking-wider'}>Quarterly Scheme Maintenance fee</td>
                                <td className={' py-5 px-6 text-left text-sm  text-gray-700 tracking-wider'}>$2.5	</td>
                                <td className={' py-5 px-6 text-left text-sm  text-gray-700 tracking-wider'}>N50</td>
                            </tr>
                            <tr  className={'hover:bg-gray-100'}>
                                <td className={' py-5 px-6 text-left text-sm  text-gray-700 tracking-wider'}>Card Funding fee		</td>
                                <td className={' py-5 px-6 text-left text-sm  text-gray-700 tracking-wider'}>$1.5	</td>
                                <td className={' py-5 px-6 text-left text-sm  text-gray-700 tracking-wider'}>N0</td>
                            </tr>
                    </tbody>
                </table>


            </div>

            <h2 className="text-lg font-bold mb-2 mt-4">3. SELF CARD CONNECT</h2>
            <p className="text-gray-600 mb-2">You are required to pay a connection and integration fee of N200,000 to
                WBA.</p>
            <p className="text-gray-600 mb-4">Provide us with your card APIs and credentials only. We will integrate
                them for you, assuming you already have a card manufacturer and processing bank or partner.</p>


        </div>
    );
};

export default PricingTables;