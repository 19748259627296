
import {ArrowUpRightIcon} from "@heroicons/react/20/solid";
import {Link} from "react-router-dom";

const CustomPlanCard = () => {
    return (
        <div className={'max-w-6xl mx-auto my-24 text-center'}>
                <h2 className="text-2xl font-bold text-center">Do You Need a Custom plan, White-label or Standalone host solution? </h2>
                <Link to={'/contact'} className={'bg-amber-400 px-6 py-4 mt-4 text-lg hover:shadow font-bold inline-flex align-center justify-center rounded-full hover'}>
                    <span>{"Let's talk."}</span> <ArrowUpRightIcon className={"w-8"}/>
                </Link>

        </div>
    );
};

export default CustomPlanCard;