import Navigation from "../../components/hoc/Navigation";
import ContactCards from "../../components/contact/contactCards";
const ContactPage = () => {
    return (
        <div>
            <Navigation/>
            <div className="relative isolate px-6 pt-14 lg:px-8">

                <div className="mx-auto max-w-6xl py-8 md:py-14">
                    <ContactCards/>
                </div>



            </div>

        </div>
    );
};

export default ContactPage;
