import React from 'react';
import FaqItem from "./faqItem";
import faqData from "./faqData";
const Faqs = () => {

    return (
        <div className={'my-16'}>
            <div className="">
                <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl text-center">FAQs</h2>
            </div>
            <div className="my-6">
                {
                    faqData.map(e=> <FaqItem key={e.title} title={e.title} body={e.body}/>)
                }

            </div>
        </div>
    );
};

export default Faqs;