import {CheckIcon} from "@heroicons/react/20/solid";
import {useEffect, useState} from "react";
import {numberWithCommas} from "./numbersToCommas";
import {Link} from "react-router-dom";

const PriceCard = ({data,type,currency,value}) => {
    const [price,setPrice] = useState(data?.price || 0);

    useEffect(()=>{

        let num = parseFloat(data.price.toString());
       if (currency!=="USD"){
           if(type.toLowerCase()==="yearly"){

               setPrice(value * (num * 12).toFixed(2))
           }else {
               setPrice(value * (num).toFixed(2))
           }
       }else{
           if(type.toLowerCase()==="yearly"){
               setPrice( (num * 12).toFixed(2))
           }else {
               setPrice( data.price)
           }
       }
    },[value,type,currency,data.price])
    return (
        <div className={data?.tag?'border-amber-500 bg-gray-50  border-4 p-6  rounded-3xl':'border-slate-300 bg-gray-50 border p-6 rounded-3xl'}>

                {
                    data?.tag?   <div className=" mb-4 sm:flex sm:justify-start "> <div
                        className="rounded-full px-3 py-1 w-28  md:w-auto text-sm leading-6 text-gray-50 bg-blue-500">
                        Most Popular
                    </div>
                    </div>:null
                }
            <h4 className="text-lg font-bold">{data?.title}</h4>
            <p className="mt-4">{data?.description}</p>
            <h2 className="text-4xl font-bold mt-4">{currency==='NGN'?'N':currency}{numberWithCommas(price)} <span className={'text-sm'}>/{type.slice(0,-2)}</span></h2>
            <Link to={'https://safe.wbalite.com/register'} target={"_blank"} className={data?.tag?"bg-amber-500 hover:bg-amber-600 text-amber-50 rounded-md border w-full mt-6 p-2 text-center font-bold block ":" block border-amber-400 hover:border-amber-600 text-amber-500 rounded-md border w-full mt-6 p-2 text-center font-bold"}>Get Started</Link>
            <ul className={'list-none mt-4'}>
                {
                    data?.features.length>0?data.features.map((e,i)=> <li key={i+e.title} className={'flex align-middle mt-2 items-center'}>
                        <span className={'p-1  rounded-full w-10 h-10 flex justify-center align-middle'}><CheckIcon className={'w-6 text-green-600'}/></span>
                        <span className={'text-md text-gray-700 ml-2'}>{e.title}<span className="text-amber-500 ml-2">{e.description}</span></span>
                    </li>):null
                }
            </ul>
        </div>
    );
};

export default PriceCard;