import React from 'react';
import Navigation from "../../components/hoc/Navigation";
import Faqs from "../../components/home/Faqs";
import Footer from "../../components/hoc/Footer";
import useScrollToTop from "../../components/hoc/scrollToTop";

const FaqsPage = () => {
    useScrollToTop();

    return (
        <div>
            <Navigation/>

           <div className="my-28">
               <Faqs/>
           </div>
            <Footer/>

        </div>
    );
};

export default FaqsPage;