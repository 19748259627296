
import {DevicePhoneMobileIcon, EnvelopeIcon,WifiIcon,CommandLineIcon, CreditCardIcon} from '@heroicons/react/24/outline'
const Whatsapp = ()=> <svg xmlns="http://www.w3.org/2000/svg" className={'fill-black w-24 m-auto p-2'}  viewBox="0 0 50 50">    <path d="M25,2C12.318,2,2,12.318,2,25c0,3.96,1.023,7.854,2.963,11.29L2.037,46.73c-0.096,0.343-0.003,0.711,0.245,0.966 C2.473,47.893,2.733,48,3,48c0.08,0,0.161-0.01,0.24-0.029l10.896-2.699C17.463,47.058,21.21,48,25,48c12.682,0,23-10.318,23-23 S37.682,2,25,2z M36.57,33.116c-0.492,1.362-2.852,2.605-3.986,2.772c-1.018,0.149-2.306,0.213-3.72-0.231 c-0.857-0.27-1.957-0.628-3.366-1.229c-5.923-2.526-9.791-8.415-10.087-8.804C15.116,25.235,13,22.463,13,19.594 s1.525-4.28,2.067-4.864c0.542-0.584,1.181-0.73,1.575-0.73s0.787,0.005,1.132,0.021c0.363,0.018,0.85-0.137,1.329,1.001 c0.492,1.168,1.673,4.037,1.819,4.33c0.148,0.292,0.246,0.633,0.05,1.022c-0.196,0.389-0.294,0.632-0.59,0.973 s-0.62,0.76-0.886,1.022c-0.296,0.291-0.603,0.606-0.259,1.19c0.344,0.584,1.529,2.493,3.285,4.039 c2.255,1.986,4.158,2.602,4.748,2.894c0.59,0.292,0.935,0.243,1.279-0.146c0.344-0.39,1.476-1.703,1.869-2.286 s0.787-0.487,1.329-0.292c0.542,0.194,3.445,1.604,4.035,1.896c0.59,0.292,0.984,0.438,1.132,0.681 C37.062,30.587,37.062,31.755,36.57,33.116z"/></svg>

const features = [
    {
        name: 'Whatsapp Banking',
        description: 'Allows customers to access banking services and support via WhatsApp messaging app for a more convenient banking experience.',
        icon: Whatsapp,
    },
    {
        name: 'Mobile Banking',
        description:
            'Provides customers with access to banking services on their mobile devices for 24/7 banking.',
        icon: DevicePhoneMobileIcon,
    },
    {
        name: 'USSD Banking',
        description:
            'The USSD-based mobile banking service eliminates the need for internet connectivity and enables customers to perform banking transactions anytime, anywhere using Non Smart-phone such as check balance, transfer, bills and  payment collection.',
        icon: EnvelopeIcon,
    },
    {
        name: 'Internet Banking',
        description:
            "Internet banking is an online service that enables customers to access and manage their bank accounts via the Internet. Users can perform various banking transactions, It's a secure and convenient way to manage finances from anywhere with an internet connection.",
        icon: WifiIcon,
    },
    {
        name: 'Physical & Virtual Card',
        description:
            'Create and manage your own virtual and physical cards from anywhere, design your cards and packaging to look and feel like your brand leveraging on our infrastructure.',
        icon: CreditCardIcon,
    },
    {
        name: 'API Infrastructure',
        description:
            'We enable seamless integration between software systems for increased efficiency and streamlined processes.',
        icon: CommandLineIcon,
    }
]

export default function FeaturesSection() {
    return (
        <div className="bg-white py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:text-center">
                    <h2 className="text-base font-semibold leading-7 text-amber-400">Grow faster</h2>
                    <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                        Everything you need to Build and Manage your Business
                    </p>
                </div>
                <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
                    <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
                        {features.map((feature) => (
                            <div key={feature.name} className="relative pl-16">
                                <dt className="text-base font-semibold leading-7 text-gray-900">
                                    <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-amber-400 text-black">
                                        <feature.icon className="h-6 w-6 text-black"  aria-hidden="true" />
                                    </div>
                                    {feature.name}
                                </dt>
                                <dd className="mt-2 text-base leading-7 text-gray-600">{feature.description}</dd>
                            </div>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    )
}