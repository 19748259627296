import Navigation from "../../components/hoc/Navigation";
import PricingHero from "../../components/pricing/pricingHero";
import Footer from "../../components/hoc/Footer";
import PricingTables from "../../components/pricing/PricingTables";
import CustomPlanCard from "../../components/pricing/CustomPlanCard";
import useScrollToTop from "../../components/hoc/scrollToTop";
import {useCallback, useEffect, useState} from "react";
import axios from "axios";

export default function Pricing() {
useScrollToTop();
    const [currencyCode, setCurrencyCode] = useState('');

    const fetchCountry = useCallback(() => {
        axios.get(`https://api.ipify.org/?format=json`)
            .then(ip=>{
                axios.get(`/api/country/${ip.data.ip}`)
                    .then(res=>{
                        console.log(res.data.data)
                        setCurrencyCode(res.data.data.currencyCode);
                    })
                    .catch(err=>{
                        console.log(err)
                    })
            })
            .catch(err=>{
                console.log(err)
            })



    },[])



    useEffect(()=>{
        fetchCountry()
    },[fetchCountry])
    return <div>

        <Navigation/>
        {currencyCode.length>0?<PricingHero currencyCode={currencyCode}/>:null}
        <CustomPlanCard/>
        <PricingTables/>
        <Footer/>
    </div>
}

