import React from "react";
import {Link} from "react-router-dom";
const ContactCards = () => {
    return (
        <div className={'w-full rounded-3xl px-6 py-12 md:py-28 h-full'}>

                <div className="grid grid-cols-1 md:grid-cols-3 ">
                    <div className="">
                        <h4 className="text-3xl font-bold">Get in touch</h4>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4 col-span-2 md:mt-0">
                        <div className="bg-gray-100 rounded-2xl p-8">
                            <h6 className="text-lg font-medium">Request Demo</h6>
                            <Link to={'https://calendly.com/wbalite/30min?month=2023-05'} className={'bg-amber-400 px-6 py-4 mt-4 text-lg hover:shadow font-bold inline-flex align-center justify-center rounded-full '}>
                                Pick a Date
                            </Link>
                        </div>
                        <div className="bg-gray-100 rounded-2xl p-8">
                            <h6 className="text-lg font-medium">Email Us</h6>
                            <p className="text-gray-500 text-sm mt-2">
                                technical support:   support@wbalite.com



                            </p>
                            <p className="text-gray-500 text-sm mt-4">

                                For inquiry: hi@wbalite.com
                                sales@wbalite.com
                            </p>
                            <p className="text-gray-500 text-sm mt-4">

                                For fraud related: frauddesk@wbalite.com

                            </p>
                            <p className="text-gray-500 text-sm mt-4">


                                Third-party Integration team:  tech@wbalite.com

                            </p>
                        </div>
                        <div className="bg-gray-100 rounded-2xl p-8">
                            <h6 className="text-lg font-medium">Call or WhatsApp</h6>
                            <p className="text-gray-500 text-sm mt-2">
                                +2348055988585
                            </p>
                            <p className="text-gray-500 text-sm mt-4">
                                +2349030306672
                            </p>
                            <p className="text-gray-500 text-sm mt-4">
                                +2347018179174
                            </p>
                        </div>
                    </div>
                </div>

            <hr className="border-b-0 border my-12  border-gray-100"/>
                <div className="grid grid-cols-1 md:grid-cols-3">
                    <div className="">
                        <h4 className="text-3xl font-bold">Locations</h4>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4 md:mt-0 col-span-2">
                        <div className="bg-gray-50 rounded-2xl p-8">
                            <h6 className="text-lg font-medium">Nigeria</h6>
                            <p className="text-gray-500 text-sm mt-2">Principal Office
                                No 29, Second floor,
                                Purple Stone, Apo Resettlement,
                                FCT- Abuja. Nigeria
                            </p>

                        </div>
                        <div className="bg-gray-50 rounded-2xl p-8">
                            <h6 className="text-lg font-medium">US</h6>
                            <p className="text-gray-500 text-sm mt-4">
                            2.30 N Gould St, STE R
                            Sheridan, WY 82801
                            USA
                        </p>
                        </div>
                    </div>
                </div>
        </div>
    );
};

export default ContactCards;