import { Routes, Route} from "react-router-dom";
import Home from "../pages/Home";
import Contact from "../pages/contact/index";
import Pricing from "../pages/pricing/index";
import Error404 from "../pages/error404/error404";
import About from "../pages/About/about";
import FaqsPage from "../pages/faqs/faqsPage";
import Onboard from "../pages/onboard/onboard";
const CustomRouter =()=>{
    return (<Routes>
            <Route path={'/'} element={<Home/>}/>
            <Route path={'/contact'} element={<Contact/>}/>
            <Route path={'/pricing'} element={<Pricing/>}/>
            <Route path={'/terms'} element={<About/>}/>
            <Route path={'/faqs'} element={<FaqsPage/>}/>
            <Route path={'/onboard'} element={<Onboard/>}/>
            <Route path={'/*'} element={<Error404/>}/>
        </Routes>)

};
export default CustomRouter;