import {Link} from "react-router-dom";


const PosSection = () => {
    return (
        <div className={' p-8 rounded-3xl relative isolate lg:px-8'}>

            <div className="grid m-auto grid-cols-1  gap-10  max-w-6xl md:grid-cols-2 sm:flex-col-reverse">

                <div className=" rounded-3xl  h-48 md:h-72 flex align-middle justify-center pos-card" >

                </div>
                <div className="">
                    <h4 className="text-3xl font-bold leading-8">WiredBanking Terminals</h4>
                    <p className="mt-4 text-md text-gray-600 leading-7">Experience the convenience of instant payment confirmation with our reliable Point of Sale (PoS) system. Our PoS allows you to keep accurate and up-to-date records of your transactions on a daily basis. Additionally, our system offers you the ability to assign and reassign PoS to different customers, as well as the ability to block and unblock PoS. You also have full control over transaction charges with our real-time REST API /UI/UX.
                    </p>
                    <p className="mt-2 text-md text-gray-600 leading-7">With our PoS system, you can enjoy the benefits of a user-friendly interface and fast processing times. Say goodbye to the hassle of manual record-keeping and enjoy the efficiency that our system provides. </p>
                    <Link to={'/pricing'} className="text-sm font-semibold mt-8 inline-block bg-amber-400 rounded-full px-6 py-4 hover:bg-amber-500  text-gray-900">Get Started</Link>
                </div>
            </div>
        </div>
    );
};

export default PosSection;