import Logo from '../../assets/logo_alt.svg'
import React from "react";
import {ArrowUpRightIcon} from "@heroicons/react/20/solid";
import {Link} from "react-router-dom";

import TawkMessenger from "@tawk.to/tawk-messenger-react";
const Footer = () => {
    return (
        <div className={'bg-gradient-to-br from-black to-gray-950 py-24 relative isolate overflow-hidden '}>
            <div className="grid max-w-6xl mx-auto  px-6 lg:px-0  gap-6 grid-cols-2 md:grid-cols-2 lg:grid-cols-4">
                <div className="">
                    <Link href="/" className="">
                        <span className="sr-only">Wiredbanking </span>
                        <img
                            className="h-8 w-auto"
                            src={Logo}
                            alt=""
                        />
                    </Link>



                    <h6 className="text-md mt-4 font-bold leading-6 text-gray-400">Our Locations</h6>
                    <p  className="text-sm font-medium text-slate-500 mt-4 "> Principal Office
                        No 29, Second floor,
                        Purple Stone, Apo Resettlement,
                        FCT- Abuja. Nigeria</p>


                    <p  className="text-sm font-medium text-slate-500 mt-4 ">  2. 30 N Gould St, STE R
                        Sheridan, WY 82801
                        USA</p>

                    <Link to={'/contact'} target={"_blank"} className={'bg-amber-400 px-6 py-4 mt-8 text-lg hover:shadow font-bold inline-flex align-center justify-center rounded-full hover'}>
                        <span>{"Let's talk."}</span><ArrowUpRightIcon className={"w-8"}/>
                    </Link>

                </div>
                <div className="flex flex-col">
                    <h6 className="text-md font-bold leading-6 text-gray-400">Features</h6>
                    <p  className="text-sm font-medium text-slate-500 mt-4 hover:text-amber-300">
                        Cardless Withdrawal</p>
                    <p  className="text-sm font-medium text-slate-500 mt-4 hover:text-amber-300">
                        Virtual / Internal Account</p>
                    <p  className="text-sm font-medium text-slate-500 mt-4 hover:text-amber-300">

                        Bills Payment</p>
                    <p  className="text-sm font-medium text-slate-500 mt-4 hover:text-amber-300">

                        VTU top-up</p>
                    <p  className="text-sm font-medium text-slate-500 mt-4 hover:text-amber-300">
                        Nigeria Bank transfer</p>
                    <p  className="text-sm font-medium text-slate-500 mt-4 hover:text-amber-300">
                        Internet banking</p>
                    <p  className="text-sm font-medium text-slate-500 mt-4 hover:text-amber-300">
                        USSD banking
                    </p>
                    <p  className="text-sm font-medium text-slate-500 mt-4 hover:text-amber-300">
                        Whatsapp Banking
                    </p>
                </div>
                <div className="flex flex-col">
                    <h6 className="text-md font-bold leading-6 text-gray-400">Key Services</h6>
                    <p className="text-sm font-medium text-slate-500 mt-4 hover:text-amber-300">
                        International Transfer</p>
                    <p className="text-sm font-medium text-slate-500 mt-4 hover:text-amber-300">
                        Nibss Collection</p>
                    <p className="text-sm font-medium text-slate-500 mt-4 hover:text-amber-300">
                        Nibss Direct Debit mandate</p>
                    <p className="text-sm font-medium text-slate-500 mt-4 hover:text-amber-300">
                        Remita  Direct Debit mandate</p>
                    <p className="text-sm font-medium text-slate-500 mt-4 hover:text-amber-300">
                        Payout?  Auto instant settlement</p>
                    <p className="text-sm font-medium text-slate-500 mt-4 hover:text-amber-300">
                        Core banking</p>
                    <p className="text-sm font-medium text-slate-500 mt-4 hover:text-amber-300">
                        API integration</p>
                    <p className="text-sm font-medium text-slate-500 mt-4 hover:text-amber-300">
                        <Link to={'/pricing'}>Pricing</Link>
                    </p>
                    <p className="text-sm font-medium text-slate-500 mt-4 hover:text-amber-300">

                        <Link to={'/faqs'}>
                            FAQ
                        </Link></p>
                    <p className="text-sm font-medium text-slate-500 mt-4 hover:text-amber-300">

                        <Link to={'/onboard'}>
                            Onboarding
                        </Link></p>
                </div>
                <div className="flex flex-col">
                    <h6 className="text-md font-bold leading-6 text-gray-400">Our Market</h6>
                    <p  className="text-sm font-medium text-slate-500 mt-4 hover:text-amber-300">Lender</p>
                    <p  className="text-sm font-medium text-slate-500 mt-4 hover:text-amber-300">NeoBank</p>
                    <p  className="text-sm font-medium text-slate-500 mt-4 hover:text-amber-300">SACCO</p>
                    <p  className="text-sm font-medium text-slate-500 mt-4 hover:text-amber-300">Microfinance Institutions</p>
                    <p  className="text-sm font-medium text-slate-500 mt-4 hover:text-amber-300">Microfinance Banks,</p>
                    <p  className="text-sm font-medium text-slate-500 mt-4 hover:text-amber-300">Fintech</p>
                    <p  className="text-sm font-medium text-slate-500 mt-4 hover:text-amber-300"> NGOs</p>
                    <p  className="text-sm font-medium text-slate-500 mt-4 hover:text-amber-300"> Cooperative societies </p>
                </div>
            </div>
            <div className="max-w-6xl mx-auto px-6 lg:px-0 ">

                <hr className="border-b-0 border my-6  border-gray-700"/>
                <div className="flex justify-between">
                    <p className="text-sm text-gray-400">Wired Banking Ltd &copy; 2023</p>
                </div>
                <p className="text-xs text-gray-500 mt-1">Wired Banking Africa is a registered trademark of Wobilo Africa Limited with RC No: 1732793 a duly registered legal entity in Nigeria. All Virtual Payment services are powered by Our Partner; Providus Bank licensed by the Central Bank of Nigeria. Wired banking financial services is provided under a MFI License regulatory provision with registration number RC:34343 in collabration with Asset matix Bank.</p>
            </div>
            <TawkMessenger propertyId={"5ccafe332846b90c57ac8877"} widgetId={"1ghtq6773"}/>
        </div>
    );
};

export default Footer;