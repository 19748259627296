import wbaCard from '../../assets/cards.png'
import { BanknotesIcon,  BoltIcon, CodeBracketSquareIcon, FlagIcon} from "@heroicons/react/20/solid";
const GlobalSection = () => {
    return (
        <>
            <div className="bg-white py-12 sm:py-24">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto max-w-2xl lg:text-center">
                        <h2 className="text-base font-semibold leading-7 text-amber-400">Work Smoother</h2>
                        <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                            Create and Manage Virtual & Physical Payments
                        </p>
                    </div>
                    <div className={' md:p-8 rounded-3xl   relative isolate lg:px-8'}>
                        <div className="grid m-auto grid-cols-1 mt-12 md:mt-8 gap-3 max-w-6xl md:grid-cols-2 sm:flex-col-reverse">

                            <div className=""><div className="grid grid-cols-1 md:grid-cols-2 gap-y-4 gap-x-2 ">
                                <div className="">


                                    <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-amber-400">
                                    <CodeBracketSquareIcon  className="h-6 w-6 text-black" aria-hidden="true"/>
                                    </div>
                                    <p className="ttext-base mt-2 font-semibold leading-7 text-gray-900">Millions of API requests monthly </p>
                                    <p className="mt-2 text-base leading-7 text-gray-600">Our infrastructure efficiently manages millions of API requests every month.</p>
                                </div>
                                <div className="">

                                    <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-amber-400">
                                    <BoltIcon className="h-6 w-6 text-black" aria-hidden="true"/>
                                    </div>
                                    <p className="text-base mt-2 font-semibold leading-7 text-gray-900">Lightning Fast Server</p>
                                    <p className="mt-2 text-base leading-7 text-gray-600">Our Servers scale to meet your business needs</p>
                                </div>
                                <div className="">

                                    <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-amber-400">

                                        <BanknotesIcon className="h-6 w-6 text-black" aria-hidden="true"/>
                                    </div>
                                    <p className="ttext-base mt-2 font-semibold leading-7 text-gray-900">99.95% Uptime
                                    </p>
                                    <p className="mt-2 text-base leading-7 text-gray-600">Experience 99.95% uptime with our reliable and efficient system. </p>
                                </div>
                                <div className="">
                                    <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-amber-400">
                                        <FlagIcon className="h-6 w-6 text-black" aria-hidden="true" />
                                    </div>
                                    <p className="text-base mt-2 font-semibold leading-7 text-gray-900">10+ Countries</p>
                                    <p className="mt-2 text-base leading-7 text-gray-600">Our Systems provides support for more than 10 Currencies </p>
                                </div>
                            </div>
                            </div>
                            <div className=" rounded-3xl  h-48 md:h-72 flex align-middle justify-center" >
                                <img src={wbaCard} alt={''} width={500} height={500} className={'w-fit rounded-3xl'}/>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
};

export default GlobalSection;