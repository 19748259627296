import './App.css';
import CustomRouter from "./routes/customRoutes";

function App() {
  return (
    <div className="">
      <CustomRouter/>
    </div>
  );
}

export default App;
