let planData = [
    {
        title:'MVP Trial Plan',
        price:"4.96",
        description:"This is suitable to market a product",
        tag:false,
        features:[
            {
                title:'All plans have same features',
                description:""
            },
            {
                title:'WBL Open API access',
                description:""
            },
            {
                title:'30 days free subscription',
                description:""
            },
            {
                title:'staff/field Officer',
                description:"2"
            },
            {
                title:'Branch/Office',
                description:"1"
            },
            {
                title:'T&C Apply',
                description:""
            }
        ],
        textBtn:"Get"
    },

    {
        title:'Starter',
        price:"7.75",
        description:"This is suitable for small scale owners",
        tag:true,
        features:[

            {
                title:'All plans have same features',
                description:""
            },
            {
                title:'WBL Open API access',
                description:""
            },
            {
                title:'30 days free subscription',
                description:"T&C Apply"
            },
            {
                title:'staff/field Officer',
                description:"4"
            },
            {
                title:'Branch/Office',
                description:"1"
            },
            {
                title:'T&C Apply',
                description:""
            }
        ],
        textBtn:"Buy Plan"
    },

    {
        title:'Starter Plus',
        price:"12.4",
        description:"This is suitable for growing business owners",
        tag:false,
        features:[

            {
                title:'All plans have same features',
                description:""
            },
            {
                title:'WBL Open API access',
                description:""
            },
            {
                title:'30 days free subscription',
                description:"T&C Apply"
            },
            {
                title:'staff/field Officer',
                description:"8"
            },
            {
                title:'Branch/Office',
                description:"2"
            },
            {
                title:'T&C Apply',
                description:""
            }
        ],
        textBtn:"Buy Plan"
    },

    {
        title:'Business',
        price:"21.7",
        description:"This is suitable for medium scale business owners",
        tag:true,
        features:[

            {
                title:'All plans have same features',
                description:""
            },
            {
                title:'WBL Open API access',
                description:""
            },
            {
                title:'30 days free subscription',
                description:""
            },
            {
                title:'Free USSD CODE Sub String',
                description:""
            },
            {
                title:'staff/field Officer',
                description:"15"
            },
            {
                title:'Branch/Office',
                description:"4"
            },
            {
                title:'T&C Apply',
                description:""
            }
        ],
        textBtn:"Buy Plan"
    },

    {
        title:'Company',
        price:"38.75",
        description:"This is suitable for Business Expansion",
        tag:false,
        features:[


            {
                title:'All plans have same features',
                description:""
            },
            {
                title:'WBL Open API access',
                description:""
            },
            {
                title:'30 days free subscription ',
                description:""
            },
            {
                title:'Free USSD CODE Sub String',
                description:""
            },
            {
                title:'staff/field Officer',
                description:"25"
            },
            {
                title:'Branch/Office',
                description:"8"
            },
            {
                title:'T&C Apply',
                description:""
            }
        ],
        textBtn:"Buy Plan"
    },

    {
        title:'Entrepreneur',
        price:"55.8",
        description:"This is suitable for Business Expansion",
        tag:true,
        features:[

            {
                title:'All plans have same features',
                description:""
            },
            {
                title:'WBL Open API access',
                description:""
            },
            {
                title:'30 days free subscription',
                description:""
            },
            {
                title:'Free USSD CODE Sub String',
                description:""
            },
            {
                title:'staff/field Officer',
                description:"40"
            },
            {
                title:'Branch/Office',
                description:"15"
            },
            {
                title:'T&C Apply',
                description:""
            }
        ],
        textBtn:"Buy Plan"
    },

    {
        title:'Express',
        price:"74.09",
        description:"This is suitable for Fast Growing Business",
        tag:false,
        features:[

            {
                title:'All plans have same features',
                description:""
            },
            {
                title:'WBL Open API access',
                description:""
            },
            {
                title:'30 days free subscription',
                description:""
            },
            {
                title:'Free USSD CODE Sub String',
                description:""
            },
            {
                title:'Customized Mobile Application',
                description:""
            },
            {
                title:'staff/field Officer',
                description:"60"
            },
            {
                title:'Branch/Office',
                description:"20"
            },
            {
                title:'T&C Apply',
                description:""
            }
        ],
        textBtn:"Buy Plan"
    },
    {
        title:'Mega',
        price:"99.2",
        description:"This is suitable for Ultra Large Scale Businesses",
        tag:false,
        features:[

            {
                title:'All plans have same features',
                description:""
            },
            {
                title:'WBL Open API access',
                description:""
            },
            {
                title:'30 days free subscription',
                description:""
            },
            {
                title:'Free USSD CODE Sub String',
                description:""
            },
            {
                title:'Customized Mobile Application',
                description:""
            },
            {
                title:'staff/field Officer',
                description:"100"
            },
            {
                title:'Branch/Office',
                description:"30"
            },
            {
                title:'T&C Apply',
                description:""
            }
        ],
        textBtn:"Buy Plan"
    },
    {
        title:'Advance',
        price:"124",
        description:"This is suitable for Ultra Large Scale Businesses",
        tag:false,
        features:[

            {
                title:'All plans have same features',
                description:""
            },
            {
                title:'WBL Open API access',
                description:""
            },
            {
                title:'30 days free subscription',
                description:""
            },
            {
                title:'Free USSD CODE Sub String',
                description:""
            },
            {
                title:'Customized Mobile Application',
                description:""
            },
            {
                title:'staff/field Officer',
                description:"150"
            },
            {
                title:'Branch/Office',
                description:"40"
            },
            {
                title:'T&C Apply',
                description:""
            }
        ],
        textBtn:"Buy Plan"
    }
]


export default planData;