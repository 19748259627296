import React from "react";
import {Link} from "react-router-dom";
import hero from '../../assets/hero.png'
export default function HomeHero() {
    return (
        <div className="relative isolate px-6 pt-14 lg:px-8">
            <div className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-2xl sm:-top-80"
                 aria-hidden="true">
                <div
                    className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#E5A924] to-[#FFFFFF] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                    style={{clipPath: 'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)'}}></div>
            </div>
            <div className="mx-auto max-w-6xl py-12 sm:py-24 lg:py-36">
                <div className="grid grid-cols-1 md:grid-cols-2">

                    <div className="">
                        <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">All-in-one core Banking management system in Africa</h1>
                        <p className="mt-6 text-lg leading-8 ">Our software is specifically designed to automate the daily operations of various financial institutions, including NeoBank, SACCO, MFI, MFB, Fintech, Minibanks, NGOs, Cooperative society and associations.</p>
                        <div className="mt-10 flex items-center  gap-x-6">
                            <Link to="https://safe.wbalite.com/register"
                                  target={"_blank"}
                               className="rounded-full bg-amber-400 px-6 py-4 text-sm font-semibold text-gray-900 shadow-sm hover:bg-amber-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-600">Get
                                started</Link>
                            <Link to="/contact" className="text-sm font-semibold leading-6 text-gray-900">Contact Us <span
                                aria-hidden="true">→</span></Link>
                        </div>
                    </div>
                    <div className="ml-3">
                        <img src={hero} alt={''} width={500} height={500} className={'w-full'}/>
                    </div>
                </div>
            </div>

            <div
                className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
                aria-hidden="true"
            >
                <div
                    className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#FFFFFF] to-[#E5A924] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                    style={{
                        clipPath:
                            'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                    }}
                />
            </div>
        </div>
    )
}
