import React from "react";
import Navigation from "../components/hoc/Navigation";
import HomeHero from "../components/home/HomeHero";
import Partners from "../components/home/Partners";
import Footer from "../components/hoc/Footer";
import ApiSection from "../components/home/apiSection";
import PosSection from "../components/home/PosSection";
import JoinUs from "../components/home/joinUs";
import AboutSection from "../components/home/aboutSection";
import GlobalSection from "../components/home/globalSection";
import FeaturesSection from "../components/home/FeatureSection";
import useScrollToTop from "../components/hoc/scrollToTop";


export default function Home() {
  useScrollToTop();
  return (
    <main
      className={``}
    >
      <Navigation/>
      <HomeHero/>
      <Partners/>
      <AboutSection/>
      <FeaturesSection/>
      <div className={'max-w-6xl md:my-24 my-12  mx-auto'}>
          <h6 className="text-xl text-center text-gray-600 ">More Than 3000+ Businesses use our services</h6>
      </div>
      <ApiSection/>
      <GlobalSection/>
      <PosSection/>
      <JoinUs/>
      <Footer/>
    </main>
  )
}
export async function getStaticProps(context) {
  return {
    props: {}, // will be passed to the page component as props
  };
}