import React from 'react';
import Navigation from "../../components/hoc/Navigation";

const About = () => {
    return (
        <>
            <Navigation/>
            <div className="">
                <div className="container my-24 bg-gray-50 px-8 rounded-2xl max-w-6xl mx-auto py-8">
                    <h1 className="text-3xl font-bold mb-6">Terms of Service</h1>
                    <p className="mb-4">These Terms of Service are a contract between you and WOBILO AFRICA LIMITED
                        (referred to in these Terms
                        of Service as "WBA", "us", "we", or "our"), the provider of the WBA website, and the services
                        accessible from the
                        WBA website (which are collectively referred to in these Terms of Service as the "WBA")</p>

                    <h2 className="text-2xl font-bold mb-4">1. Your Account</h2>
                    <p className="mb-4">1.1 Eligibility: Your account must be registered by a human. Accounts registered
                        by "bots" or other
                        automated methods are not permitted. Additionally, you must be 18 years and above.</p>
                    <p className="mb-4">1.2 Registration Information: During the registration process, you must supply a
                        working permanent
                        email address, a working phone number, your BVN (for Nigerian merchants) or NIN (for foreign
                        merchants), as well as
                        any additional information the WBA compliance team may request during kyc. A single person or
                        company is only
                        permitted to have one account.</p>
                    <p className="mb-4">1.3 Restrictions: You agree not to use the WBA service in or for any illegal,
                        unauthorized or improper
                        manner or purpose and agree that the WBA Service will only be used in a way that complies with
                        all applicable laws,
                        rules, and regulations, including all appropriate state, federal and international internet,
                        data,
                        telecommunications, telemarketing, "spam", and import/export laws and regulations. Without
                        limitation, you agree
                        that you will not breach this restriction by:</p>
                    <ul className="list-disc pl-8 mb-4">
                        <li>Violating or infringing on the rights of any third party, including without limitation any
                            intellectual property
                            rights, privacy rights, or publicity rights;
                        </li>
                        <li>Disseminating material that is obscene, offensive, fraudulent, deceptive, defamatory,
                            threatening, harassing,
                            abusive, slanderous, hateful, or causes embarrassment to any third party as determined by
                            WBA in its sole
                            discretion; or
                        </li>
                        <li>Purposefully misrepresenting your identity, impersonating someone else.</li>
                    </ul>
                    <p className="mb-4">Disseminate any material that would expose us or any related third party to
                        liability.</p>

                    <div className="py-4">
                        <h2 className="text-lg font-bold mb-2">2. PAYMENT | SUBSCRIPTION</h2>
                        <p className="text-gray-600 mb-4">2.1 A valid credit card is required for automated recurring
                            payments. Payment may also be made via Wallet or bank transfer, or other payment methods
                            that may be enabled from time to time.</p>

                        <h2 className="text-lg font-bold mb-2">3. DISPUTE AND REFUND, UPGRADING, AND DOWNGRADING</h2>
                        <p className="text-gray-600 mb-4">When a Transaction is performed, and the recipient cannot
                            receive payment, this cannot be due to the provider network or internal error. You are to
                            engage the WBA team with your reference ID after twenty-four (24) hours of the transaction.
                            Resolution or consigns should be resolved within the end of the business. If not successful,
                            payment will be refunded to your account.</p>

                        <h2 className="text-lg font-bold mb-2">4. INFLOW, INWARD PAYMENT REFUNDABLE</h2>
                        <p className="text-gray-600 mb-4">When a transfer is made to you, or you received payment from a
                            third-party bank, and we were engaged from the source account provider (bank) stating the
                            funds are illegitimate or fraudulent, funds will be held, and an internal investigation will
                            commence. If this turns out to be accurate, funds will be reversed or refunded back to the
                            origin.</p>
                    </div>

                    <div className="py-4">
                        <h2 className="text-lg font-bold mb-2">5. SUBSCRIPTION REFUND</h2>
                        <p className="text-gray-600 mb-4">When a new business joins WBL and guide videos and training
                            materials are provided, the following will occur:</p>
                        <p className="text-gray-600 mb-4">The business will be allowed to test the solution within three
                            (3) working days (72 hours), which is referred to as a free trial period. If it meets the
                            business need and they decide to onboard, a one-time onboarding fee and subscription are
                            required to go live.</p>
                        <p className="text-gray-600 mb-4">Onboarding fees are non-refundable as other third-party
                            services are involved.</p>
                        <p className="text-gray-600 mb-4">Subscriptions are refundable; subject to review if they meet
                            our internal policy within three (3) working days.</p>

                        <h2 className="text-lg font-bold mb-2">6. REFUNDABLE AND NON-REFUNDABLE</h2>
                        <p className="text-gray-600 mb-4">Here are the situations in which you can expect us to give a
                            refund:</p>
                        <ul className="list-disc pl-6 mb-4">
                            <li className="text-gray-600">The platform is "not as described."</li>
                            <li className="text-gray-600">The platform does not work the way it should, as stated in the
                                video or live demo.
                            </li>
                            <li className="text-gray-600">The platform has not been activated.</li>
                        </ul>

                        <h2 className="text-lg font-bold mb-2">7. Non-refundable</h2>
                        <p className="text-gray-600 mb-4">Suppose the platform is materially similar to the description
                            and preview and works the way it should. In that case, there is generally no obligation to
                            provide a refund in situations like the following:</p>
                        <ul className="list-disc pl-6">
                            <li className="text-gray-600">You do not want it after you've activated your account.</li>
                            <li className="text-gray-600">You changed your mind.</li>
                            <li className="text-gray-600">You paid and activated your account by mistake.</li>
                            <li className="text-gray-600">You do not have sufficient expertise to use the platform.</li>
                            <li className="text-gray-600">You did not receive support on time.</li>
                            <li className="text-gray-600">You can no longer access our API because our platform is not
                                accessible on your device.
                            </li>
                        </ul>
                    </div>


                    <div className="py-4">
                        <h2 className="text-lg font-bold mb-2">8. TERMS AND TERMINATION</h2>
                        <p className="text-gray-600 mb-4">At least five (5) days before the expiration of the Initial
                            Service Period, the Customer may renew for a month or one (1) year term unless one party
                            provides the other party with written notice of termination. If the customer does not renew
                            before the expiration of the Initial Service Period, all Support Services will be
                            terminated.</p>
                        <p className="text-gray-600 mb-4">Your WBL account will be temporarily suspended, and access to
                            the service will be denied for B2B - B2C. However, during this phase, your data is safe and
                            backup and can be accessed when you renew.</p>

                        <h2 className="text-lg font-bold mb-2">9. SECURITY BREACH</h2>
                        <ul className="list-disc pl-6 mb-4">
                            <li className="text-gray-600">Internal Breach: You are solely responsible for taking
                                corrective action in the event of an internal security breach, as that term is defined
                                by applicable law, caused by an employee, contractor, or agent of yours, or as a result
                                of your failure to maintain your systems, network, or data in a secure manner. You must
                                also notify Wired Banking Africa right away of the breach and the steps you intend to
                                take to address it. To protect you, us, the service, or other third parties from harm,
                                we reserve the right to take any action, including suspending your access to the
                                service. You give up the right to sue us for any losses you sustain as a result of our
                                activities.
                            </li>
                            <li className="text-gray-600">External Breach: In the event that a security breach, as
                                defined by applicable law, is caused by a person other than an employee, contractor, or
                                agent of yours, Wired Banking Africa will: (a) initiate remedial actions that are
                                compliant with applicable law and consistent with industry standards; and (b) notify you
                                of the security breach, its nature and scope, the remedial actions Wired Banking Africa
                                will take, and the timeframe within which Wired Banking Africa will complete them.
                            </li>
                        </ul>

                        <h2 className="text-lg font-bold mb-2">10. DATA PROTECTION</h2>
                        <p className="text-gray-600 mb-4">The parties herein agree to strictly comply with all
                            applicable privacy and data protection laws, such as the Nigeria Data Protection Regulation,
                            as well as all other applicable regulations and directives in collecting, using, processing,
                            handling, and transferring any the confidential information disclosed by a party in
                            connection with the service, which may contain any personal data. Each party agrees to fully
                            indemnify the other party if there is any breach of any applicable laws concerning privacy
                            and data protection laws by such party.</p>

                        <h2 className="text-lg font-bold mb-2">11. AMENDMENTS</h2>
                        <p className="text-gray-600 mb-4">This privacy and cookie policy may be amended from time to
                            time consistent with the requirements of the General Data Protection Regulation. We will
                            post any revised policy on this website.</p>
                    </div>

                        <h2 className="text-lg font-bold mb-2"> NIGERIA DATA PROTECTION REGULATION</h2>
                        <p className="text-gray-600 mb-4">Under the Nigeria Data Protection Regulation 2019 (NDPR),
                            there are certain occasions when Wired Banking Africa must obtain your explicit consent to
                            use your information, known as 'Personal Data'. Personal Data includes but is not limited to
                            your name, address, telephone number, email address, card details, and Bank Verification
                            Number (BVN).</p>
                        <p className="text-gray-600 mb-4">We shall hold and use your information for the purposes set in
                            the Data Processing Notice, which requires consent under the Privacy Policy. You shall grant
                            Wired Banking Africa consent to collect your name, official Government ID Number (ID
                            Number), and photo image.</p>

                        <h3 className="text-lg font-bold mb-2">Details Collection</h3>
                        <p className="text-gray-600 mb-4">Wired Banking Africa may share this information with a
                            third-party service provider to validate it against a government source. The third-party
                            service provider may use your ID Number to gather your additional data, such as:</p>
                        <ul className="list-disc pl-6 mb-4">
                            <li className="text-gray-600">Full Name</li>
                            <li className="text-gray-600">Date of Birth</li>
                            <li className="text-gray-600">ID Number</li>
                            <li className="text-gray-600">Image</li>
                            <li className="text-gray-600">Address</li>
                            <li className="text-gray-600">Phone Number</li>
                            <li className="text-gray-600">Gender</li>
                        </ul>
                        <p className="text-gray-600 mb-4">This data helps confirm your identity and may be processed or
                            stored outside the borders of Nigeria.</p>

                        <h2 className="text-lg font-bold mb-2">12. WARRANTIES, DISCLAIMERS, AND EXCLUSIVE REMEDIES</h2>
                        <p className="text-gray-600 mb-4">12.1 Wired Banking Africa warrants that support services will
                            be provided in a professional manner consistent with industry standards. Customer must
                            notify WBA of any service deficiencies within two (2) days (48) from performance of the
                            defective services.</p>
                        <p className="text-gray-600 mb-4">WBA does not guarantee that the software will perform
                            error-free or uninterrupted or that WBA will correct all software errors immediately.</p>

                        <p className="text-gray-600 mb-4">12.2 for any breach of the above warranties, customer’s
                            exclusive remedy and WBA’s entire liability shall be: (a) the correction of software errors
                            that cause breach of the warranty; or, if WBA cannot substantially correct such breach in a
                            commercially reasonable time frame, customer may end the services period and recover a
                            prorated portion of any maintenance fees prepaid to WBA; or (b) the reperformance of the
                            deficient support services.</p>
                        <p className="text-gray-600 mb-4">To the extent permitted by law, these warranties are exclusive
                            and there are no other express or implied warranties or conditions including warranties or
                            conditions of merchantability and fitness for a particular purpose.</p>

                    <div className="py-4">
                        <h2 className="text-lg font-bold mb-2">13. ABUSE AND MISUSE OF SUPPORT</h2>
                        <p className="text-gray-600 mb-4">Abuse to WBA support platforms in the form of spam,
                            unsolicited contents, submission of requests in multiple-duplicates, use of "offensive or
                            abusive" contents and other similar abuses are prohibited. WBA reserves the right to
                            terminate access to its entire or part of support platform on the bases of abuse, with or
                            without notice</p>

                        <h2 className="text-lg font-bold mb-2">14. MISCELLANEOUS PROVISIONS</h2>
                        <p className="text-gray-600 mb-4">14.1 confidentiality. Neither party will disclose any support
                            services-related terms, conditions, and/or fee amounts to any third party, unless required
                            to do so by order of any court or governmental authority of competent jurisdiction.</p>
                        <p className="text-gray-600 mb-4">14.2 binding. No assignment. This agreement shall be binding
                            upon and inure to the benefit of the parties and their successors, permitted assigns, and
                            legal representatives. Customer may not assign, sublicense, or otherwise transfer the
                            support services unless in conjunction with a transfer of the software.</p>
                        <p className="text-gray-600 mb-4">14.3 language. This agreement has been agreed to only in the
                            English language. This English language version will control regardless of whether any
                            translations of this agreement have been prepared or exchanged. Customer acknowledges and
                            represents that it has carefully reviewed this agreement with the involvement and assistance
                            of your employees, advisors, and/ or legal counsel fluent in the English language, that it
                            has consulted with local legal counsel and counsel competent to render advice with respect
                            to transactions governed by the law applicable to this agreement, that it has no questions
                            regarding the meaning or effect of any of this agreement’s terms, and that it has obtained
                            high-quality translations of this agreement for use by customer or any of customer’s team
                            who are not fluent in the English language, with the understanding that customer alone will
                            bear the risk of any misunderstandings that may arise as a result of such translation. All
                            communications in connection with this agreement will be in the English language.</p>
                        <p className="text-gray-600">14.4 notices to WBA. Any notices to WBA under this agreement must
                            be in writing and either delivered in person, sent by email, or first class mail (if within
                            the United States), or sent by air courier to the address set forth below. Notices will be
                            considered to have been given at the time of actual delivery in person, five (5) business
                            days after deposit in the mail, or two business (2) days after delivery to an air courier
                            service or confirmation of email receipt.</p>
                    </div>

                    <div className="py-4">
                            <h2 className="text-lg font-bold mb-2">15. PUBLICITY</h2>
                            <p className="text-gray-600 mb-4">The Customer agrees that WBA may disclose that the
                                Customer is using the WBA Software for the Customer's business operations. In this
                                regard, WBA may use the Customer's name, trade mark, trade name, service mark, and logo
                                (collectively referred to as the trademarks) to identify the Customer as a WBA client on
                                the marketing information, either on the WBA website or elsewhere.</p>

                        </div>


                    </div>


            </div>

        </>
    );
};

export default About;