import {useState} from "react";

const PricingSwitch = ({setType,index,setIndex}) => {
    const [types] = useState(['Monthly','Yearly'])
    return (
        <div className="rounded-full border border-gray-400 p-2 my-8 w-52 bg-gray-100 m-auto grid grid-cols-2 gap-1 align-center">
            {
                types.map((e,i)=><span key={i} onClick={()=>{
                    setIndex(i);
                    setType(e)
                }} className={index===i?"bg-amber-400 px-4 py-2 rounded-full hover:bg-amber-500 text-center":"bg-transparent text-center px-4 py-2"}>{e}</span>)
            }
        </div>
    );
};

export default PricingSwitch;