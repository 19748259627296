let faqData = [
    {
       title:"The Benefits Of Wired Banking Lite:?\n" ,
        body:"Wired Banking Lite is a more advanced and upgraded version of Wired Banking Africa, designed to meet changing and evolving demands.\n",
    },
    {
       title:"Can I visit Wired Banking Office?" ,
        body:"Yes, you can visit the Wired Banking office by scheduling an appointment or a demo presentation. To schedule, please click here. https://calendly.com/wbalite/30min\n",
    },
    {
       title:"What Year was Wired Banking Found?\n"
          ,
        body: "Wired Banking Africa was founded in 2019, went live as a central system in 2020, and was registered the same year in Nigeria and the US.\n",
    },
    {
       title:"Who is the Founder of Wired Banking Africa?\n" ,
        body:"Chinedu Shema Emmanuel is the founder and chief executive of Wired Banking Africa | Wobilo Africa Limited and Smart Frame Network, located at Principal Office No 29, Second floor, Purple Stone, Apo Resettlement, FCT- Abuja. Nigeria.\n",
    },
    {
       title:"Why Wired Banking Lite?\n" ,
        body:"Wired Banking Lite offers premium features and more control. It provides affordable and reliable banking tools for every institution. Including creating internal and Nuban virtual accounts, and accepting payments from and to any bank..  USSD banking, Whatsapp banking, and Internet banking service are also available on API integration",
    },
    {
       title:"Can Existing Customers Migrate?\n" ,
        body:"Yes, existing customers can migrate as long as their companies are validated.\n",
    },
    {
       title:"What are the other fees and charges of Wired Banking Africa?\n" ,
        body:"Wiredbanking has a transparent charge stated here: https://wbacore,com/pricing",
    },
    {
       title:"How safe/Secured is my Data with Wired Banking Africa\n" ,
        body:"We take precautional steps to protect your information with us. All your transactions and users' data are safely backup regularly in the cloud server.",
    },
    {
       title:"What happens, if i refuse or forget to renew my subscription?\n" ,
        body:"No need to panic, your data's are safe, your account will just be temporary lock and will be unlocked as soon as you renew®.\n",
    },
    {
       title:"Will my customer receive sms alert with my sender ID?" ,
        body:"Yes! SMS alerts are custom, which means you can use any name you want as your sender ID.\n",
    },
    {
       title:"Can i connect any SMS API for Alert if i want to?" ,
        body:"Yes, you are able to connect external sms API to push out sms alerts across your customer.",
    },
    {
       title:"What are the benefit of using Wired Banking Africa?" ,
        body:`Access to WBA Unlimited Banking tools <br>
                    - Transparent/Affordable monthly fee <br>
                      - Unlimited customer/member registration based on your preferred plan<br>
                      -Earn 2% of any amount of Airtime/Data Purchase by you or your customers,<br>
                      - Earn N80% On each bills payments done by your customer<br>
                      - time-to-time discount.<br>
                      -Earn 20% -50% for any new cooperative you introduce to WBA after successful onboarding.<br>
                      - Partner with WBA and earn upto - 50k to 100k monthly.-T&C apply.
                      - Access to over 5M overdraft T&C apply.
`,
    },
    {
       title:"Who can use Wired Banking Africa?" ,
        body:`Wired Banking Africa is design for both New Startup and growing cooperative societies, MFI, MFB, Mini-Bank, Thrift Collectors, Fintech and Associations who seek to digitalize their Financial operation.`,
    },
    {
       title:"Can i get customized Physical ATM card for my customer\n" ,
        body:`Yes, we offer co-branded card.. design just for your business `,
    },
    {
       title:"can i have my own USSD code or Whatsapp bot banking number\n" ,
        body:`Yes, you can have a dedicated or Share code or Sub string share USSD code for your customer with a nonsmart phone to use for transactions.
`,
    },
    {
       title:"can i have my own Whatsapp bot banking number\n" ,
        body:`yes, you can have a personalized WhatsApp banking number for your customer to be able to carry out transactions from their WhatsApp,  such as checking balance sending and receiving payment automatically
.
`,
    },
    {
       title:"can i have my own customized app\n" ,
        body:`Yes we offer customize app`,
    },
    {
       title:"can I have my own Standalone core banking\n" ,
        body:`Yes we offer standalone`,
    },
    {
       title:"Can I migrate from subscription to Payoff/Standalone" ,
        body:`Yes we support migration to standalone`,
    },
    {
       title:"Must i visit your office before i can get a starter or use your service\n" ,
        body:`NO. service is cloud-based,, you simply need to signup and complete kyc and Setup fee online and a business account will be created for you. `,
    },
    {
       title:"Can i terminate my data ?" ,
        body:`YES. you can terminate your data anytime you want..`,
    },
    {
       title:"What is your backup or alternative option in case of loss of data ?\n" ,
        body:`we have multiple options i place to ensure the security of our data, Auto backup, and cloud backup, we also send you backup daily to your email as well you are able to download it manually from your dashboard.`,
    },
    {
       title:"Can i bring in my own API and integrate on wired banking\n" ,
        body:`Yes we support third-party integration  and Additional features`,
    },
    {
       title:"Can i Integrated Wire banking into my existing app?\n" ,
        body:`Yes/. We have API available for integrations.`,
    },
    {
       title:"Do you support NIBSS integration\n" ,
        body:`Yes we support..  and we have done that in the past such as inward and Outward alongside NUBAN generation
`,
    },
    {
       title:"Which bank powers wired banking services?\n" ,
        body:`Wired banking is integrated with over 15 banks and 8 fintech making it service topnotch like no other with different options for merchants to ensure seamless service and 99.9% uptime`,
    },
    {
       title:"What happens if my transaction failed ?\n" ,
        body:`We have a structured plan to ensure auto reversal or manual reversal within 3 -24 hours on business hours.`,
    },
]
export default faqData;