import React from 'react';
import Navigation from "../../components/hoc/Navigation";
import OnboardRequirements from "../../components/home/onboardRequirements";
import Footer from "../../components/hoc/Footer";
import useScrollToTop from "../../components/hoc/scrollToTop";
import whatsapp  from '../../assets/Whatsapp banking_google-pixel4-clearlywhite-portrait.png'
import cgate  from '../../assets/Cgate USSD_google-pixel4-ohsoorange-portrait.png'
import ussd  from '../../assets/USSD banking_google-pixel4-ohsoorange-portrait.png'
import mac  from '../../assets/phone_imac2013_left.png'
import mobileApp  from '../../assets/phone_google-pixel4-justblack-portrait.png'
const Onboard = () => {
    useScrollToTop()
    return (
        <div>
            <Navigation/>
            <div className="my-28">



                <h2 className="text-3xl my-32 font-bold leading-8 text-center">Wired Banking onboarding
                </h2>

                <div className="grid m-auto grid-cols-1  gap-10  max-w-6xl md:grid-cols-2 items-center px-4">

                    <div className="w-fit md:max-w-[20rem]">
                        <img src={mac} alt="Whatsapp Banking" className={'w-fit'}/>

                    </div>
                    <div className="">
                        <h5 className="text-3xl font-bold ">All-in-one core Banking</h5>
                        <p className="text-lg  my-2">All-in-one core banking for Application for Lenders, fintech, Cooperative Society and Thrift collector</p></div>
                </div>
                <div className="grid m-auto grid-cols-1  gap-10  max-w-6xl md:grid-cols-2 items-center px-4">

                    <div className="">
                        <h5 className="text-3xl font-bold ">Whatsapp Banking</h5>
                        <p className="text-lg  my-2">Whatsapp is my Banking bot .. allow your customer to transact from their WhatsApp directly</p></div>
                    <div className="w-fit md:max-w-[20rem]">
                        <img src={whatsapp} alt="Whatsapp Banking" className={'w-fit'}/>

                    </div>
                </div>
                <div className="grid m-auto grid-cols-1  gap-10  max-w-6xl md:grid-cols-2 items-center px-4">

                    <div className="w-fit md:max-w-[20rem]">
                        <img src={ussd} alt="Whatsapp Banking" className={'w-fit'}/>

                    </div>
                    <div className="">
                        <h5 className="text-3xl font-bold ">USSD Banking</h5>
                        <p className="text-lg  my-2">USSD Offline banking channel  for your non smartphone customer</p></div>
                </div>
                <div className="grid m-auto grid-cols-1  gap-10  max-w-6xl md:grid-cols-2 items-center px-4">

                    <div className="">
                        <h5 className="text-3xl font-bold ">Mobile Banking</h5>
                        <p className="text-lg  my-2">Launch a white-labeled Mobile application with your business name and logo</p></div>
                    <div className="w-fit md:max-w-[20rem]">
                        <img src={mobileApp} alt="Whatsapp Banking" className={'w-fit'}/>

                    </div>
                </div>
                <div className="grid m-auto grid-cols-1  gap-10  max-w-6xl md:grid-cols-2 items-center px-4">

                    <div className="w-fit md:max-w-[20rem]">
                        <img src={cgate} alt="Whatsapp Banking" className={'w-fit'}/>

                    </div>
                    <div className="">
                        <h5 className="text-3xl font-bold ">Receive USSD Payments</h5>
                        <p className="text-lg  my-2">Accept payment from offline customer using your unique USSD</p></div>
                </div>

                <OnboardRequirements/>
            </div>

            <Footer/>

        </div>
    );
};

export default Onboard;