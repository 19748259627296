import {Link} from "react-router-dom";

const JoinUs = () => {
    return (
        <div className={'my-8 mx-4 md:mx-auto bg-transparent'}>
                <div className="bg-black max-w-6xl md:py-12 mx-4 lg:mx-auto  md:px-24 py-8 px-8 rounded-3xl m-auto grid md:grid-cols-2 sm:grid-cols-1">
                    <h6 className="text-3xl font-bold text-gray-200">
                        Get Started & Explore Many new Possibilities
                    </h6>

                    <div className="md:text-right sm:text-left mt-3 ">
                        <Link target={"_blank"} to={'https://safe.wbalite.com/register'} className="bg-amber-400 px-6 py-4 mt-4 text-lg hover:shadow font-bold inline-flex align-center justify-center rounded-full">Get Started</Link>
                    </div>
                </div>
        </div>
    );
};

export default JoinUs;