let PriceListData = [
    {
        type:'USSD CODE CONFIGURATION AND PRICING',
        description:"Note: To activate your WBL USSD code, you only need to fund your USSD wallet with a minimum of ₦2,000... all other fees are optional.",
        data:[
            {
                service:"USSD Minimum Wallet Funding",
                narration:"USSD wallet is where you will be debited (session fee) whenever your customer dials the code to transact, and the minimum you can fund is ₦2,000",
                cost:"₦2,000"
            },
            {
                service:"USSD WBL string activation",
                narration:"After your code is generated or WBL general code is assigned to your institution, your USSD code is expected to maintain a minimum balance of ₦500 to be active and a minimum of ₦2,000 to fund your wallet",
                cost:"₦500"
            },
            {
                service:"USSD WBL string",
                narration:"WBL shared string e.g., 23411*1# – generate a unique USSD code to allow your customer to register or open an account by themselves via a USSD code we will assign to your institution. (This USSD code will only work on Wired Banking server and can’t be transferred)",
                cost:"₦20,000"
            },
            {
                service:"Shared USSD",
                narration:"To own a Shared USSD for your institution e.g., 23411# – you are to engage us while we get it activated for your account (This USSD code can work on any server and ownership can be transferred)",
                cost:"₦250,000"
            },
            {
                service:"USSD dedicated",
                narration:"To own a Dedicated USSD for your institution e.g., *234# – you are to engage us while we get it activated for your account after applying from NCC e.g., Duration - 6-8 weeks (This USSD code can work on any server and ownership can be transferred)",
                cost:"₦2,500,000"
            },
            {
                service:"USSD Session/usage unit",
                narration:"Each time your customer dials the code, we charge your USSD balance with us, likewise provision option where you enter the amount to charge your customer balance",
                cost:"₦6.5"
            }
        ]
    },
    {
        type:'WHATSAPP BOT CONFIGURATION AND PRICING',
        description:"Note: To activate your WBL WhatsApp banking for your customers, you only need to fund your WhatsApp wallet with a minimum of ₦2,000... all other fees are optional.",
        data: [
            {
                service:"Whatsapp Minimum Wallet funding",
                narration:"Whatsapp wallet is where you will be debited (session fee) whenever your customer completes a transaction via the WhatsApp banking Channel, and the minimum you can fund is ₦2,000",
                cost:"₦2,000"
            },
            {
                service:"Whatsapp Banking activation fee",
                narration:"Your WhatsApp Banking wallet balance must maintain a minimum of ₦500",
                cost:"₦500"
            },
            {
                service:"WhatsApp Banking session",
                narration:"Each time your customer completes a transaction, we charge your WhatsApp balance with us ₦3, likewise provision option where you enter the amount to charge your customer balance.",
                cost:"₦3"
            },
            {
                service:"WhatsApp Banking Dedicated own Number",
                narration:"To configure our WhatsApp banking on your own dedicated phone number, a one-time fee of ₦50,000 is required to connect. Session fee remains the same.",
                cost:"₦50,000"
            },
        ]
    },
    {
        type:"POS ( POINT OF SALES ) PRICING:",
        description: `Cash out transactions are currently 0.55% cap N85
Bank transfer N20 flat
Bills and Top up Commission 3%
`,
        data: [
            {
                service: "Android POS",
                narration: "Deploy your own customized POS terminal, co-branded with your company name and logo, as well as determine pricing.",
                cost: "Outright - ₦85,000"
            },
            {
                service: "Analog POS",
                narration: "Set up a POS terminal uniquely customized for your business and co-branded. Determine the pricing you want to charge.",
                cost: "Outright purchase- ₦40,500 and ₦50,500"
            },
            {
                service: "MPOS",
                narration: "Coming soon",
                cost: "-"
            },
            {
                service: "Mobile POS",
                narration: "Turn your mobile phone into POS and start accepting card payments. Coming soon.",
                cost: "-"
            }
        ],
        meta: ()=>{
            return <>
                <h2 className="text-lg font-bold mb-2 mt-4">POS DIRECT CONNECT AND WBA POS</h2>
                <p className="text-gray-600 mb-2"> <span className={'text-amber-500 font-bold'}>WBA POS</span>: You can purchase a minimum of 1 terminal from us, and the
                    commercial rates are as follows: 0.55% capped at N85. Analog terminal costs N40,500, and digital
                    terminal costs N50,500. <span className={'text-amber-500 font-bold'}>
                    Terminal name
                </span>: NEWpos. Commission on bills is 1.5%, with instant settlement. You
                    can set your own pricing, reassign, block, and unblock POS from your dashboard.</p>
                <p className="text-gray-600 mb-4"><span className={'text-amber-500 font-bold'}>CUSTOMIZED POS:</span> You can purchase a minimum of 20 terminals from a vendor,
                    and the commercial rates are as follows: 0.5% capped at N80. Analog terminal costs N40,500, and digital
                    terminal costs N50,500. <span className={'text-amber-500 font-bold'}>Terminal name:</span> NEWpos. Commission on bills is 2.5%, with instant settlement. You
                    can set your own pricing, reassign, block, and unblock POS from your dashboard.</p>

                <h2 className="text-lg font-bold mb-2">SELF POS CONNECT</h2>
                <p className="text-gray-600 mb-2">You are required to pay a connection and integration fee of N50,000 to
                    WBA.</p>
                <p className="text-gray-600 mb-4">Provide us with your POS APIs and credentials only. We will integrate them
                    for you, assuming you already have a PPSP, POS terminals, and a processing bank or partner.</p>
                <p className="text-gray-600 mb-4">Note: This option is only for businesses that already have a bank or
                    fintech that agrees to provide them with POS GATEWAY API.</p>

                <h2 className="text-lg font-bold mb-2">CUSTOMIZED FEATURE OF API</h2>
                <p className="text-gray-600 mb-4">Contact us to open a discussion if you would like to bring your API to
                    integrate with your Wired Banking Lite Account!</p>

                <h2 className="text-lg font-bold mb-2">SELF BACKUP</h2>
                <p className="text-gray-600 mb-2">Backup Monitor is now live. You can now request us to enable backup
                    service on your account. This option will send you an email every day with the summary of your daily
                    activities EOD and the list of all your customers and their balance. This service costs N100 daily.</p>


            </>
        }

    }
]

export let aptData = [
    {
        type:"Lowo Wallet Mobile App",
        cost:"Free",
        details:"Customers can download Lowo Wallet from a playstore and log in with credentials generated for them on the welcome message. Customers can login and carry out transactions eg: check their balance, Pay bills and bank transfer from your custom domain name, and work on all devices. (setup duration is 2 minutes)"
    },
    {
        type:"Custom Web App",
        cost:"₦10,000",
        details:"Assign a subdomain eg: companyname.wba.ng cost N10,000. Customers can register, log in, carry out transactions eg: check their balance, Pay bills and bank transfer from your custom domain name, and work on all devices. (setup duration is 2 minutes)"
    },
    {
        type:"Custom Lowo App (Android)",
        cost:"₦80,000",
        details:"To customize or white-label our Lowo Wallet app with your business name, logo, and color and upload it on Playstore, a one-time fee applies. (setup duration is 4-7 days)"
    },
    {
        type:"Custom Lowo App (IOS)",
        cost:"₦100,000",
        details:"To customize or white-label our Lowo Wallet app with your business name, logo, and color and upload it on IOS store, a one-time fee applies. (setup duration is 4-7 days). Store fee applies."
    },
    {
        type:"Custom Web App (Dedicated Domain Name)",
        cost:"₦80,000",
        details:"A dedicated URL or domain 'eg: companyname.com, org, or ng' N80k with a two-page website that shows more about your organization. Customers can register, login, and carry out transactions from your website. (setup duration is 3-14 days)"
    }
]
export default PriceListData;