import React from 'react';

const Error404 = () => {
    return (
        <div className={'max-w-6xl mx-auto my-28 text-center'}>
            <h6 className="text-5xl">ERROR, PAGE NOT FOUND</h6>
        </div>
    );
};

export default Error404;