import React, {useCallback, useEffect, useState} from 'react';
import PricingSwitch from "../../components/pricing/pricingSwitch";
import Plans from "../../components/pricing/Plans";
import Axios from "axios";

const PricingHero = ({currencyCode}) => {
    const [index,setIndex] = useState(0);
    const [ind,setInd] = useState(0);

    const [type,setType] = useState("Monthly");
    const [currency,setCurrency] = useState('USD');
    const [value,setValue] = useState(0.0);
    const [currencies] = useState(['USD',currencyCode]);
    const getDefault =  useCallback(()=>{


        Axios.get(`/api/currency/${currency}`)
            .then(res=>{
                let v = parseFloat((res.data.data).toFixed(2))
                setValue(v);
                console.log(v)
            })
            .catch(err=>{
                console.log(err)
            })
    },[currency]);
    useEffect(()=>{
        getDefault()
    },[getDefault])

    return (

        <div className="relative isolate px-6 pt-14 lg:px-8">
            <div className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-2xl sm:-top-80"
                 aria-hidden="true">
                <div
                    className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#E5A924] to-[#FFFFFF] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                    style={{clipPath: 'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)'}}></div>
            </div>
            <div className="mx-auto max-w-2xl py-8 md:py-14">

                <div className="text-center">
                    <h1 className="text-3xl font-bold text-gray-900 sm:text-6xl">
                        Pricing plans
                    </h1>
                    <p className="mt-6 text-lg leading-8 ">Choose an affordable plan tailored to your business needs with a one-time setup fee of $15 + KYC and enjoy a 30-day free subscription. You can conveniently make payments using various local currency options, such as card, mobile money, USSD, NQR, or bank transfer.
                    </p>
                </div>
            </div>
            <div className="rounded-full border border-gray-400 p-2 my-8 w-52 bg-gray-100 m-auto grid grid-cols-2 gap-1 align-center ">
                {
                    currencies.map((e,i)=><button key={e} onClick={()=>{
                        setInd(i);
                        setCurrency(e)
                    }} className={ind===i?"bg-gray-900 px-1 py-1 rounded-full text-white hover:bg-gray-800 text-center":"bg-transparent text-center px-4 py-2"}>{e}</button>)
                }
            </div>
            <PricingSwitch index={index} setType={setType} setIndex={setIndex}/>
            <Plans currency={currency} value={value} type={type}/>
        </div>
    );
};

export default PricingHero;