import {CheckIcon} from "@heroicons/react/20/solid";
import wbaVideo from '../../assets/wbavideo.mp4'
const AboutSection = () => {
    return (
        <div className={'md:my-24 sm:my-12 '}>
            <div className="max-w-6xl mx-auto ">

                <div className="grid py-8 gap-x-10 md:gap-x-4 gap-y-12 md:gap-y-36 grid-cols-1 md:grid-cols-2 mx-6 lg:grid-cols-4 mt-8">

                    <div className=" col-span-2">
                        <div className="bg-black min-h-full p-4 rounded-3xl">
                            <video className={'rounded-md'} src={wbaVideo} controls>
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                    <div className="col-span-2   rounded-3xl md:ml-2">

                        <h6 className="text-3xl font-bold ">The Solution For Your Business</h6>
                        <p className="mt-6 text-md leading-7 text-gray-600">We leverage technology to provide both online and offline solutions, making it easier for our customers to access financial services from anywhere at any time. Our software is simple and intuitive, enabling financial institutions to manage their operations more efficiently, increase their revenue and reduce their operational costs. We also place great emphasis on data security, and our platform is built to protect sensitive information and transactions from unauthorized access. This provides peace of mind to our customers and allows them to focus on their core business activities</p>
                        <div className="mt-2 grid grid-cols-2">
                            <ul className={'list-none'}>
                                <li className={'flex align-middle mt-6 items-center'}>
                                    <span className={' rounded-full w-6 h-6 flex justify-center align-middle'}><CheckIcon className={'w-6 text-green-600'}/></span>
                                    <span className={'text-base leading-7 textgray-600 ml-2'}>Account Management</span>
                                </li>
                                <li className={'flex align-middle mt-6 items-center'}>
                                    <span className={' rounded-full w-6 h-6 flex justify-center align-middle'}><CheckIcon className={'w-6 text-green-600'}/></span>
                                    <span className={'text-base leading-7 textgray-600 ml-2'}>  Savings management
                                  </span>
                                </li>
                                <li className={'flex align-middle mt-6 items-center'}>
                                    <span className={' rounded-full w-6 h-6 flex justify-center align-middle'}><CheckIcon className={'w-6 text-green-600'}/></span>
                                    <span className={'text-base leading-7 textgray-600 ml-2'}>    Loan management
                                  </span>
                                </li>
                                <li className={'flex align-middle mt-6 items-center'}>
                                    <span className={' rounded-full w-6 h-6 flex justify-center align-middle'}><CheckIcon className={'w-6 text-green-600'}/></span>
                                    <span className={'text-base leading-7 textgray-600 ml-2'}>      Ebanking Management

                                  </span>
                                </li>
                                <li className={'flex align-middle mt-6 items-center'}>
                                    <span className={' rounded-full w-6 h-6 flex justify-center align-middle'}><CheckIcon className={'w-6 text-green-600'}/></span>
                                    <span className={'text-base leading-7 textgray-600 ml-2'}>  Investment Management
                                  </span>
                                </li>
                            </ul>
                            <ul className={'list-none'}>

                                <li className={'flex align-middle mt-6 items-center'}>
                                    <span className={' rounded-full w-6 h-6 flex justify-center align-middle'}><CheckIcon className={'w-6 text-green-600'}/></span>
                                    <span className={'text-base leading-7 textgray-600 ml-2'}>    Insurance Management

                                  </span>
                                </li>
                                <li className={'flex align-middle mt-6 items-center'}>
                                    <span className={' rounded-full w-6 h-6 flex justify-center align-middle'}><CheckIcon className={'w-6 text-green-600'}/></span>
                                    <span className={'text-base leading-7 textgray-600 ml-2'}>     Card Management

                                  </span>
                                </li>
                                <li className={'flex align-middle mt-6 items-center'}>
                                    <span className={' rounded-full w-6 h-6 flex justify-center align-middle'}><CheckIcon className={'w-6 text-green-600'}/></span>
                                    <span className={'text-base leading-7 textgray-600 ml-2'}>
                                    Payroll Management
                                  </span>
                                </li>
                                <li className={'flex align-middle mt-6 items-center'}>
                                    <span className={' rounded-full w-6 h-6 flex justify-center align-middle'}><CheckIcon className={'w-6 text-green-600'}/></span>
                                    <span className={'text-base leading-7 textgray-600 ml-2'}>

                                    POS Management
                                  </span>
                                </li>
                                <li className={'flex align-middle mt-6 items-center'}>
                                    <span className={' rounded-full w-6 h-6 flex justify-center align-middle'}><CheckIcon className={'w-6 text-green-600'}/></span>
                                    <span className={'text-base leading-7 textgray-600 ml-2'}>

                                    Report Management
                                  </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutSection;